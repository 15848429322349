const Toolbar = (props) => {
    const {title, subtitle, activeStep, displaySteps = true} = props;
    return (
        <header className="wizard-heading py-3 bg-light-grey">
            <div className="container-fluid">
                <div className="row align-items-center">
                    <div className="col-md-6 d-flex flex-column flex-md-row align-items-center text-center text-md-start mb-4 mb-md-0">
                        <div className="wizard-icon text-primary pe-md-3 mb-2 mb-md-0 lh-1">
                            <i className="fa-light fa-solar-panel"></i>
                        </div>
                        <div className="wizard-title lh-1">
                            <h4 className="mb-1">{title}</h4>
                            <p className="mb-0 text-primary">{subtitle}</p>
                        </div>
                    </div>
                    {displaySteps && <div className="col-md-6 d-flex flex-column flex-md-row align-items-center align-items-center justify-content-md-end">
                        <div className="wizard-status d-flex align-items-center">
                            {[1,2,3,4].map(num => {
                                return <span key={num.toString()} className={`num${activeStep === num ? ' current':''}`}>{num}</span>
                            })}
                        </div>
                    </div>}
                </div>
            </div>
        </header>
    )
}

export default Toolbar;