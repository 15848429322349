import { useTranslation } from 'react-i18next';

const NewModelModal = (props) => {

    const { onSubmit } = props;
    const { t } = useTranslation();

    const handleValue = (event) => {
        const v = event.target.value;
        if( v.includes(',') ){
            const searchRegExp = /,/gi;
            const replaceWith = '.';
            event.target.value = v.replace(searchRegExp, replaceWith);
        }
    }

    return (
        <div className="modal new-module-modal fade" tabIndex="-1" role="dialog" id="newModuleModal">
            <div className="modal-dialog modal-lg">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">{t('step2.model_details.new_module_title')}</h5>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body">
                        <form id="newModelForm" onSubmit={onSubmit}>
                            <div className="module-sheet">
                                <div className="body px-4">
                                    <div className="product-info mt-4">
                                        <table className="table table-bordered">
                                            <tbody>
                                                <tr>
                                                    <td className="bg-primary text-white align-middle">
                                                        <p className="mb-0">{t('step2.model_details.producer')}</p>
                                                    </td>
                                                    <td className="bg-light-grey">
                                                        <input type="text" name="produttore" className="form-control"/>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="bg-primary text-white align-middle">
                                                        <p className="mb-0">{t('step2.model_details.model')}</p>
                                                    </td>
                                                    <td className="bg-light-grey">
                                                        <input type="text" name="modello" className="form-control"/>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="bg-primary text-white align-middle">
                                                        <p className="mb-0">Pnom[Wp]</p>
                                                    </td>
                                                    <td className="bg-light-grey">
                                                        <input type="text" onChange={handleValue} name="p_nom" className="form-control"/>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="bg-primary text-white align-middle">
                                                        <p className="mb-0">Vmp[V]</p>
                                                    </td>
                                                    <td className="bg-light-grey">
                                                        <input type="text" onChange={handleValue} name="v_mp" className="form-control"/>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="bg-primary text-white align-middle">
                                                        <p className="mb-0">Imp[A]</p>
                                                    </td>
                                                    <td className="bg-light-grey">
                                                        <input type="text" onChange={handleValue} name="i_mp" className="form-control"/>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="bg-primary text-white align-middle">
                                                        <p className="mb-0">Voc[V]</p>
                                                    </td>
                                                    <td className="bg-light-grey">
                                                        <input type="text" onChange={handleValue} name="v_oc" className="form-control"/>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="bg-primary text-white align-middle">
                                                        <p className="mb-0">Isc[A]</p>
                                                    </td>
                                                    <td className="bg-light-grey">
                                                        <input type="text" onChange={handleValue} name="i_sc" className="form-control"/>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="bg-primary text-white align-middle">
                                                        <p className="mb-0">MaxSysVoltage[V]</p>
                                                    </td>
                                                    <td className="bg-light-grey">
                                                        <input type="text" onChange={handleValue} name="max_sys_v" className="form-control"/>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="bg-primary text-white align-middle">
                                                        <p className="mb-0">VOC Temp Coef[%/C]</p>
                                                    </td>
                                                    <td className="bg-light-grey">
                                                        <input type="text" onChange={handleValue} name="voc_temp_coef" className="form-control"/>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="bg-primary text-white align-middle">
                                                        <p className="mb-0">Max system voltage</p>
                                                    </td>
                                                    <td className="bg-light-grey">
                                                        <input type="text" onChange={handleValue} name="max_sys_v" className="form-control"/>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="bg-primary text-white align-middle">
                                                        <p className="mb-0">ISC Temp Pmax [%/C]</p>
                                                    </td>
                                                    <td className="bg-light-grey">
                                                        <input type="text" onChange={handleValue} name="isc_temp_pmax" className="form-control"/>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="bg-primary text-white align-middle">
                                                        <p className="mb-0">Pmax Temp Coef[%/C]</p>
                                                    </td>
                                                    <td className="bg-light-grey">
                                                        <input type="text" onChange={handleValue} name="pmax_temp_coef" className="form-control"/>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="bg-primary text-white align-middle">
                                                        <p className="mb-0">{t('step2.model_details.length')} [mm]</p>
                                                    </td>
                                                    <td className="bg-light-grey">
                                                        <input type="text" onChange={handleValue} name="lunghezza" className="form-control"/>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="bg-primary text-white align-middle">
                                                        <p className="mb-0">{t('step2.model_details.width')} [mm]</p>
                                                    </td>
                                                    <td className="bg-light-grey">
                                                        <input type="text" onChange={handleValue} name="larghezza" className="form-control"/>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <div className="cta text-center mt-5">
                                        <button type="submit" className="new-module-form-submit btn btn-lg btn-primary btn-round">{t('step2.model_details.submit_button')}</button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default NewModelModal;