const BsAlert = (props) => {
    const {show, type, message, callback, className} = props;

    return (
        show && <div className={`alert alert-${type} fade show py-2 mb-0 text-small ${className}`} role="alert">
            {message}
            <button type="button" className="btn-close" onClick={callback}></button>
        </div>
    )
}
BsAlert.defaultProps = {
    type: 'info',
    message: '',
    show: false,
    callback: null
}

export default BsAlert;