import { useState, useCallback, useEffect,  } from "react";
//import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
const ResultTable = (props) =>
{
    //const navigate = useNavigate();
    const { t, i18n } = useTranslation();
    
    const {
        model,
        inverter,
        //moduleMinTemp,
        moduleMaxTemp,
        activeButton,
        buildReport,
        handleSelectedReport
    } = props

    const [arrayTable, setArrayTable] = useState([]);
    const [tableHeaders, setTableHeaders] = useState([]);
    const [display, setDisplay] = useState(false);

    useEffect(() =>
    {
        function calcola()
        {
            /**
             * Calcolo il valore VOC del modulo
             */
            const getVocMaxModulo = function ()
            {
                const vocmodulo =  model.v_oc + model.voc_temp_coef * (25 - moduleMaxTemp);
                console.log('const vocmodulo =  ' + model.v_oc + ' + (' + model.voc_temp_coef + ' * 25 - ' + moduleMaxTemp + ');');
                console.log('vocmodulo', vocmodulo);
                return vocmodulo;
            }

            /**
             * Calcolo il valore VOC della stringa
             */
            const getVocStringa = function (multiplier)
            {
                const VocMaxModulo = getVocMaxModulo(model, inverter, moduleMaxTemp);
                
                return VocMaxModulo * multiplier;
            }
            
            /**
             * 
             * @param multiplier 
             * controllo validità della stringa
             */
            const validateStringa = function(PStringa)
            {
                return (PStringa >= (inverter.p_nom * 0.5)) && (PStringa <= (inverter.p_nom * 1.5));
            }

            /**
             * 
             * @param multiplier 
             * cntrollo validità del parallelo
             */
            const validateParallelo = function(multiplier)
            {
                const vocModulo = getVocMaxModulo();
                const min = Math.trunc(inverter.v_startup / model.v_oc);
                console.log('min = inverter.v_startup / model.v_oc = ' + inverter.v_startup + '/' + model.v_oc + ' = ' + min );
                const max = Math.trunc(inverter.v_oc / vocModulo);

                console.log('---VALIDAZIONE---');
                console.log('vocModulo = ', vocModulo);
                console.log('min = ', min);
                console.log('max = ', max);
                console.log('inverter.p_nom / model.p_nom = ', inverter.p_nom / model.p_nom);
                //console.log('isValid = ', is_valid);
                console.log('---FINE VALIDAZIONE---');

                //return is_valid;
                return false;
            }
            
            const corrente_max_inverter = inverter.i_fv_max;

            let contentArray = [];
            let minColumns = Infinity;
            let maxColumns = 0;

            for (let k = 1; k <= inverter.stringhe; k++)
            {
                //Calcolo voc modulo
                const vocModulo = getVocMaxModulo();
                //calcolo indici min e max dei moduli in serie
                const p_nom_mppt = inverter.p_nom / inverter.stringhe;
                console.log('Pnominvsingolomppt = inverter.p_nom / inverter.stringhe = ' + inverter.p_nom + '/' + inverter.stringhe + ' = ' + p_nom_mppt);
                const numModuliInSerie = Math.trunc(p_nom_mppt / (model.p_nom * k));
                console.log('N.ModuliSerie = p_nom_mppt / (model.p_nom * k) = ' + p_nom_mppt + '/' + (model.p_nom * k) + ' = ' + numModuliInSerie);
                //end calcolo indici min e max dei moduli in serie
                const minModuliInSerie = Math.round(numModuliInSerie / 2);
                let maxModuliInSerie = Math.round(numModuliInSerie * 1.5);
                const maxModuliTensione = Math.round(inverter.v_oc / vocModulo);


                let corrente_pannello = parseFloat(model.i_mp) * k;
                if( corrente_pannello > corrente_max_inverter )
                {
                    break;
                }

                if( maxModuliTensione < maxModuliInSerie )
                {
                    maxModuliInSerie = maxModuliTensione;
                }

                console.log('min-partenza = ', minModuliInSerie);
                console.log('max-partenza = ', maxModuliInSerie);
                //Numero massimo moduli in base a rapporto tensioni

                if( minModuliInSerie < minColumns )
                {
                    minColumns = minModuliInSerie;
                }

                if( maxModuliInSerie > maxColumns )
                {
                    maxColumns = maxModuliInSerie;
                }
                
                let row = [];
                
                for (let i = 1; i <= 50; i++)
                {
                    //let vocStringa = getVocStringa(i);
                    //const vStartup = inverter.v_startup;
                    //const corrente_max_inverter = inverter.i_fv_max;
                    const numPannelliInSerie = k * i;
                    const p_nom = model.p_nom;
                    const Pstringa = p_nom * numPannelliInSerie;
                    const newPNom = inverter.p_nom / inverter.stringhe;
                    let status;
                    if( Pstringa < (newPNom * 0.9) )
                    {
                        status = -1;
                    }else if( Pstringa > (newPNom * 1.1) ){
                        status = 1;
                    }else{
                        status = 0;
                    }

                    if( i >= minModuliInSerie && i <= maxModuliInSerie )
                    {
                        //console.log('numPannelliInSerie = ', numPannelliInSerie);
                        //console.log('Pstringa = ', Pstringa);

                        row[i] = {
                            value: Pstringa,
                            valid: Pstringa >= (newPNom * 0.9) && Pstringa <= (newPNom * 1.1) ? 1 : 2,
                            status: status,
                            key: i
                        }
                    }else{
                        row[i] = {
                            value: '&dash;&dash;',
                            valid: 0,
                            status: status,
                            key: i
                        }
                    }
                }
                console.log('row = ', row);

                contentArray.push(row);
            }


            if( contentArray.length ){
                let array = [...contentArray];
                const firstRow = array.find(el => true);
                console.log('firstRow', firstRow);
                let keys = [];
                for (let key in firstRow)
                {
                    key = Number(key);
                    if (firstRow[key] !== null && typeof firstRow !== 'undefined' && ( key >= minColumns && key <= maxColumns ))
                    {
                        keys.push(key);
                    }
                }
                console.log(keys);
                setTableHeaders(keys);

                // var countColumns = firstRow.length;
                // var removes = [];
                // for (let z = 0; z < countColumns; z++)
                // {
                //     var isRemove = array.every(function (val)
                //     {
                //         return val[z] === null || undefined;
                //     });
                //     if (isRemove)
                //     {
                //         removes.push(z);
                //     }
                // }
                console.log('minColumns', minColumns);
                console.log('maxColumns', maxColumns);
                var newData = array.map(function (val, ind)
                {
                    return val.filter(function (val, ind)
                    {
                        return val.key >= minColumns && val.key <= maxColumns
                    });
                });
                setArrayTable(newData);
            }

            setDisplay(true);
        }

        calcola();
    }, [inverter,model,moduleMaxTemp]);
    
    const getTableMarkup = useCallback((mppt) =>
    {
        return (
            <table key={mppt.toString()} className="table table-bordered table-sm results-table">
                <tbody>
                    <tr>
                        <td rowSpan="6" className="r1-c1 w-0 align-middle bg-primary text-white text-center"><span>MPPT {mppt}</span></td>
                        <td colSpan="2" className="r1-c2" rowSpan="2"></td>
                        <td colSpan={tableHeaders.length} className="r1-c3 text-center text-uppercase">{t('step4.results.table.heading')}</td>
                    </tr>
                    <tr>
                        {tableHeaders.map((el, index) => {
                            return <td key={el.toString()} className={`text-center r2-c${index}`}>{el}</td>
                        })}
                    </tr>
                    {arrayTable.map((row, n) => {
                        const rowIndex = n + 1;
                        return <tr key={n.toString()} className="values-row">
                            {(function(){
                                if( n === 0 )
                                {
                                    return <td rowSpan="4" className="r3-c1 text-center align-middle text-uppercase w-0"><span>{t('step4.results.table.sub_heading')}</span></td>
                                }
                            })()}
                            <td className="w-0 bg-light-grey align-middle">{rowIndex}</td>
                            {row.map((cell, j) => {
                                let htmlCell;
                                switch( cell.valid )
                                {
                                    case 1:
                                        htmlCell = (
                                            <td key={j.toString()} data-valid={cell.valid.toString()} data-inverter-id={inverter.id} data-model-id={model.id} className='text-center align-middle'>
                                                <input onChange={(e) => handleSelectedReport(e, inverter)} type="radio" className="d-none" data-value={cell.value} data-mppt={mppt} data-status={cell.status} data-valid={cell.valid} data-strigs={rowIndex} data-serie={cell.key} data-total-panels={cell.key * rowIndex} name={`selection[${inverter.id}][${mppt}]`} id={`selection-${inverter.id}-${mppt}-${rowIndex}-${cell.key}`}/>
                                                <label htmlFor={`selection-${inverter.id}-${mppt}-${rowIndex}-${cell.key}`} className={`reset-button d-flex flex-column p-2 lh-1 success`}>
                                                    <span className="label pb-1">{cell.value}</span>
                                                    <div className="icon mb-0 d-flex justify-content-center">
                                                        <i className="fa-solid fa-circle"></i>
                                                        <i className="fa-solid fa-circle-dot"></i>
                                                    </div>
                                                </label>
                                            </td>
                                        );
                                        break;
                                    case 2:
                                        htmlCell = (
                                            <td key={j.toString()} data-valid={cell.valid.toString()} data-inverter-id={inverter.id} data-model-id={model.id} className='text-center align-middle'>
                                                <input onChange={(e) => handleSelectedReport(e, inverter)} type="radio" className="d-none" data-value={cell.value} data-mppt={mppt} data-status={cell.status} data-valid={cell.valid} data-strigs={rowIndex} data-serie={cell.key} data-total-panels={cell.key * rowIndex} name={`selection[${inverter.id}][${mppt}]`} id={`selection-${inverter.id}-${mppt}-${rowIndex}-${cell.key}`}/>
                                                <label htmlFor={`selection-${inverter.id}-${mppt}-${rowIndex}-${cell.key}`} className={`reset-button d-flex  flex-column p-2 lh-1 warning`}>
                                                    <span className="label pb-1">{cell.value}</span>
                                                    <div className="icon mb-0 d-flex justify-content-center">
                                                        <i className="fa-solid fa-circle"></i>
                                                        <i className="fa-solid fa-circle-dot"></i>
                                                    </div>
                                                </label>
                                            </td>
                                        );
                                        break;
                                    case 0:
                                        htmlCell = (
                                            <td key={j.toString()} data-valid={cell.valid.toString()} className='text-center align-middle' dangerouslySetInnerHTML={{__html: cell.value}}></td>
                                        );
                                        break;
                                    default:
                                        break;
                                }
                                return htmlCell;
                            })}
                        </tr>
                    })}
                </tbody>
            </table>
        )
    }, [arrayTable, inverter.id, model.id, tableHeaders, handleSelectedReport]);

    const outputMarkup = useCallback(() => {
        if( !arrayTable.length )
        {
            const supportLinks = {
                it: 'https://www.western.it/supporto-form/',
                en: 'https://www.western.it/en/support/'
            }

            const no_results_message = t('step4.results.no_results_message').replace('%inverter%', inverter.modello).replace('%url%', supportLinks[i18n.language]);
            return (
                <div className="table-output-block no-results d-flex flex-column result-form">
                    <header className="table-output-block-header d-flex justify-content-between align-items-center">
                        <h5 className="table-output-block-heading text-start">{inverter.modello}</h5>
                    </header>
                    <div className="inverter-table-output text-center p-4">
                        <p className="mb-0" dangerouslySetInnerHTML={{__html: no_results_message}}></p>
                    </div>
                </div>
            )
        }
        let output = [];
        for( let i=1; i <= inverter.stringhe; i++ )
        {
            output.push(getTableMarkup(i));
        }
        return (
            <form onSubmit={buildReport} className="result-form">
                <input type="hidden" name="inverter" value={JSON.stringify(inverter)}/>
                <input type="hidden" name="model" value={JSON.stringify(model)}/>
                <div className="table-output-block d-flex flex-column" id={`table-output-block-${inverter.id}`}>
                    <header className="table-output-block-header d-flex justify-content-between align-items-center">
                        <h5 className="table-output-block-heading text-start">{inverter.modello}</h5>
                        <div className="table-output-block-total-modules">Tot. moduli selezionati: <span className="total">0</span></div>
                    </header>
                    <div className="inverter-table-output table-responsive">{output}</div>
                    <div className="table-output-block-cta p-2 d-flex justify-content-end bg-light-grey" id={`table-output-block-cta-${inverter.id}`}>
                        <button type="submit" disabled={activeButton !== inverter.id} className="btn table-output-block-cta-btn text-uppercase" id={`table-output-block-cta-btn-${inverter.id}`}><i className="fa-solid fa-print"></i>&nbsp;&nbsp;{t('step4.results.table.submit_btn')}</button>
                    </div>
                </div>
            </form>
        )
    },[inverter, activeButton, getTableMarkup, buildReport, arrayTable]);

    return (
        display && outputMarkup()
    )
}
export default ResultTable;