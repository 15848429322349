const Intro = (props) => {
    const { icon, title, subtitle } = props;
    return (
        <div className="info-block py-5 px-3 px-md-5 text-center">
            <div className="info-icon text-primary d-flex flex-column justify-content-center align-items-center">
                <i className={icon}></i>
            </div>
            <div className="info-text mb-3">
                <h4>{title}</h4>
                <p className="mb-0 text-mid">{subtitle}</p>
            </div>
            <div className="step-actions">
                {props.children}
            </div>
        </div>
    )
}
export default Intro;