const en = {
    translation: {
        "general": {
            "back": "Back",
            "continue_btn": "Continue"
        },
        "header": {
            "login_label": "Login",
            "welcome_message": "Welcome %user%"
        },
        "footer": {
            "italy": "Italy",
            "rights": "All Rights Reserved",
            "vat": "Vat No."
        },
        "toolbar": {
            "title": "String Sizer",
            "subtitle": "String inverter"
        },
        "step1": {
            "intro": {
                "title": "Choose the location in which to install the system and set the temperature",
                "subtitle": "Move the marker on the map or enter and edit coordinates manually."
            },
            "map": {
                "city_label": "City",
                "coords_label": "Position",
                "autocomplete_placeholder": "Enter your city",
                "latitude": "Latitude",
                "longitude": "Longitude",
                "temp_label": "Enter the operating temperature range",
                "min_temp": "Min. Temp.",
                "max_temp": "Max. Temp.",
                "modulo_max_temp": "Module Max. Temp.",
                "modulo_min_temp": "Module Min. Temp.",
            }
        },
        "step2": {
            "intro": {
                "title": "Choose a module",
                "subtitle": "Choose your module by selecting manufacturer and model, or set up a custom module"
            },
            "custom_module_button": "Custom module",
            "model_edit": "Edit",
            "model_delete": "Delete",
            "model_details": {
                "producer": "Producer",
                "model": "Model",
                "length": "Length",
                "width": "Width",
                "submit_button": "Confirm entry",
                "edit_submit_button": "Apply changes",
                "new_module_title": "Insert new module",
                "edit_module_title": "Edit custom model",
                "edit_submit_button_confirm": "All archived configurations containing this module will be deleted. Are you sure you want to proceed?",
                "delete_model_confirm": "Are you sure you want to delete this module?\nThis action cannot be undone."
            }
        },
        "step3": {
            "intro": {
                "title": "Select one or more types of inverters",
                "subtitle": "Click on the product for more information and check the box to select it"
            },
            "types": {
                "monofase": "single phase",
                "trifase": "three phase",
                "ibrido": "hybrid"
            },
            "card": {
                "details_button_label":"Details"
            },
            "inverter_details": {
               "model": "Model",
               "code": "Code",
               "mppt_inputs": "N. of MPPT inputs",
               "ac_output_voltage": "AC Output Voltage [V]",
               "ac_output_frequency": "AC Output Frequency [Hz]",
            },
            "mppt_header": "{{ count }} MPPT Array",
            "mppt_header_plural": "{{ count }} MPPT Arrays"
        },
        "step4": {
            "intro": {
                "title": "Configuration results",
                "subtitle": "Lorem ipsum dolor sit amet consectetur adispiscing elit",
                "cta_btn": "Save configuration",
                "update_btn": "Apply changes"
            },
            "data_entered": "Data entered",
            "location": "Location",
            "temperature": "Temperature",
            "selected_model": "Selected model",
            "selected_inverters": "Selected inverters",
            "results":{
                "title": "Your configuration",
                "no_results_message": "Sorry but a valid configuration could not be found with the <strong>%inverter%</strong> inverter.<br/><a href=\"%url%\">contact the Western CO</a> for more information",
                "table": {
                    "heading": "Panels in series",
                    "sub_heading": "Strings in parallel",
                    "submit_btn": "Print configuration report"
                }
            }
        },
        "register": {
            "welcome": "<strong>Welcome!</strong><br/>To register, enter your details.",
            "already_registered": "Already registered?",
            "form": {
                "title": "Register",
                "labels": {
                    "firstname": "First name",
                    "lastname": "Last name",
                    "email": "E-mail",
                    "password": "Password",
                    "confirm": "Confirm password"
                },
                "placeholders": {
                    "firstname": "Enter your first name",
                    "lastname": "Enter your last name",
                    "email": "Enter your e-mail",
                    "password": "Enter your password",
                    "confirm": "Confirm password"
                },
                "submit_label": "Register",
            }
        },
        "account": {
            "title": "My account",
            "personal_info": {
                "title": "Personal information",
                "intro": "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
                "firstname": {
                    "label": "First name",
                    "placeholder": "Enter your firstname",
                    "error": "Enter your firstname"
                },
                "lastname": {
                    "label": "Last name",
                    "placeholder": "Enter your lastname",
                    "error": "Enter your lastname"
                },
                "save": "Save changes"
            },
            "email_address": {
                "title": "E-mail address",
                "intro": "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."
            }
        },
        "dashboard_nav": {
            "account_label" : "My Account",
            "archive_label" : "Archive",
            "new_request_labek" : "New request"
        },
        "archive": {
            "title" : "Configurations archive",
            "subtitle": "My Configurations",
            "table": {
                "data": "Date",
                "latitude": "Latitude",
                "longitude": "Longitude",
                "t_min": "Min. temp.",
                "t_max": "Max. temp.",
                "detail_btn_label": "More info"
            },
            "delete_alert_message": "Are you sure you want to delete the #%id% configuration? This operation cannot be undone",
            "no_results": "No configuration saved"
        },
        "configuration_item": {
            "title": "Configuration details",
            "subtitle": "Configurazione #%id% of %date%"
        },
        "login": {
            "alert_after_recover": "Check your email",
            "welcome_message": "<strong>Welcome!</strong><br/>To start using the application, log in.",
            "no_account_message": "No account?",
            "form": {
                "placeholders": {
                    "email": "Enter your email",
                    "password": "Enter your password"
                },
                "errors": {
                    "email": "Enter a valid email address",
                    "password": "Enter a password of at least 8 characters"
                },
                "submit_label": "Login",
                "forgot_password": "Forgot password?"
            }
        },
        "recover": {
            "welcome_message": "Enter your e-mail address to be able to recover your password",
            "back_button": "Back to login",
            "form": {
                "placeholders": {
                    "email": "Enter your email",
                },
                "errors": {
                    "email": "Enter a valid email address"
                },
                "submit_label": "Recover password",
            }
        }
    }
}
export default en