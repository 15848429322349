import Header from '../components/Header';
import Footer from '../components/Footer';
import Toolbar from '../components/Toolbar';
import Intro from '../components/Intro';
import ResultTable from '../components/ResultTable';
import { apiRoot } from '../settings';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useCore } from "../Core";
import { useNavigate, NavLink, useParams } from 'react-router-dom';

const Step4 = () =>
{

   const { t } = useTranslation();
   const navigate = useNavigate();

   const {
      idConfigurazione
   } = useParams();

   const {
      isLoading,
      setIsLoading,
      latitude,
      longitude,
      minTemp,
      maxTemp,
      moduleMinTemp,
      moduleMaxTemp,
      model,
      inverters,
      token,
      clearAll,
      setSelectedReport
   } = useCore();

   const [activeButton, setActiveButton] = useState(null);

   const handleSelectedReport = useCallback((event, inverter) =>
   {
      const inverterId = inverter.id;
      const formElement = event.target.form;
      const selections = formElement.querySelectorAll('input[type=radio]:checked');
      //calcolo i totali dei moduli da visualizzare su ogni tabella
      let tot = 0;
      if (selections.length)
      {
         [].slice.call(selections).forEach((selection, indice) =>
         {
            const pannelli = Number(selection.dataset.serie);
            const stringhe = Number(selection.dataset.strigs);
            console.log('pannelli', pannelli);
            console.log('stringhe', stringhe);
            tot += (pannelli * stringhe);
         });
      }
      console.log('selections.length', selections.length);
      console.log('inverter.stringhe', inverter.stringhe);
      formElement.querySelector('.table-output-block-total-modules > span').innerHTML = tot;
      if (selections.length === inverter.stringhe)
      {
         setActiveButton(inverterId);
         // const yOffset = -100;
         // const element = document.getElementById(`table-output-block-cta-${inverterId}`);
         // if(element){
         //     const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
         //     window.scrollTo({top: y, behavior: 'smooth'});
         // }
      }
   }, []);

   const buildReport = (e) =>
   {
      e.preventDefault();
      const formData = new FormData(e.target);
      const selections = e.target.querySelectorAll('input[type=radio]:checked');
      const data = [];
      if (selections.length)
      {
         [].slice.call(selections).forEach((el, i) =>
         {
            const props = {};
            for (let k in el.dataset)
            {
               props[k] = Number(el.dataset[k]);
            }
            data.push(props);
         });
      }
      const reportParams = {
         inverter: JSON.parse(formData.get('inverter')),
         model: JSON.parse(formData.get('model')),
         data: data,
         latitude: latitude,
         longitude: longitude,
         minTemp: minTemp,
         maxTemp: maxTemp,
         moduleMinTemp: moduleMinTemp,
         moduleMaxTemp: moduleMaxTemp
      }
      console.log('reportParams', reportParams);
      setSelectedReport(reportParams);
      window.open('/app/print-report');
      return false;
   }

   const handleSaveConfiguration = async () =>
   {
      setIsLoading(true);
      const headers = new Headers();
      //headers.append("Content-Type", "application/json");
      const formData = new FormData();
      formData.append('lat', latitude);
      formData.append('lng', longitude);
      formData.append('temp_min', minTemp);
      formData.append('temp_max', maxTemp);
      const configurazione = {
         model: model,
         inverters: inverters,
         moduleMinTemp: moduleMinTemp,
         moduleMaxTemp: moduleMaxTemp
      };
      formData.append('configurazione', JSON.stringify(configurazione));

      let endpoint = idConfigurazione ? `${apiRoot}/configuration/${idConfigurazione}/` : `${apiRoot}/configuration/`;

      if (token)
      {
         headers.append("Authorization", `Token ${token}`);
      }
      const saved = await fetch(
         endpoint,
         {
            method: idConfigurazione ? 'PUT' : 'POST',
            headers: headers,
            body: formData
         }
      )
         .then(response => response.json())
         .catch(error =>
         {
            alert(error.message);
         });
      if (typeof saved.result !== 'undefined' && saved.result === 'error')
      {
         alert(saved.message);
         setIsLoading(false);
         return;
      }
      clearAll();
      navigate(`/admin/archive/${saved.id}`);
      setIsLoading(false);
   }

   const handleCancelChanges = (id) => {
      clearAll();
      navigate(`/admin/archive/${idConfigurazione}`);
   }

   return (
      <>
         <Header />
         <main className="app-mai bg-white">
            <section className="wizard">
               <Toolbar title={t('toolbar.title')} subtitle={t('toolbar.subtitle')} activeStep={4} />
               <div className="step-content">
                  <div className="container-fluid px-0 step step-4 bg-lighest">
                     <div className="row g-0 align-items-start">
                        <div className="layout-column first bg-white col-lg-4 d-flex flex-column bg-white sticky-top">
                           <Intro icon="fa-light fa-clipboard-list-check" title={t('step4.intro.title')} subtitle={t('step4.intro.subtitle')}>
                              <div className="info-action">
                                 {
                                    token ? (
                                       idConfigurazione ? (
                                          <>
                                          <p><button type="button" disabled={isLoading} onClick={() => handleSaveConfiguration()} className="btn btn-primary btn-lg btn-save btn-round px-4">{t('step4.intro.update_btn')}</button></p>
                                          <p><button type="button" disabled={isLoading} onClick={() => handleCancelChanges()} className="reset-button text-decoration-underline text-dark text-small">Annulla le modifiche e torna all'archivio</button></p>
                                          </>
                                       ) : (
                                          <p><button type="button" disabled={isLoading} onClick={() => handleSaveConfiguration()} className="btn btn-primary btn-lg btn-save btn-round px-4">{t('step4.intro.cta_btn')}</button></p>
                                       )
                                    ) : (
                                       <>
                                          <p><button type="button" disabled={true} className="btn btn-grey btn-lg btn-save btn-round px-4">Salva configurazione</button></p><p className='text-small'>Per salvare la tua configurazione, <NavLink to='/admin/account/login/' className="text-primary">esegui il login</NavLink></p>
                                       </>
                                    )
                                 }
                                 <p className='mb-0'><button onClick={() => navigate('/step-3')} type="button" className="reset-button text-decoration-underline text-dark text-small">{t('general.back')}</button></p>
                              </div>
                           </Intro>
                        </div>
                        <div className="layout-column last col-lg-8 p-4 p-md-5 d-flex flex-column justify-content-center">
                           <div className="wrapper">
                              <div className="full-container">
                                 <div className="boxed bg-white py-5 px-2 px-lg-4">
                                    <div className="box dati-inseriti">
                                       <header>
                                          <h3 className="text-center">{t('step4.data_entered')}</h3>
                                       </header>
                                       <div className="data-box-body mt-4">
                                          <ul className="list-group p-0">
                                             <li className="list-group-item">
                                                <div className="row align-items-center">
                                                   <div className="col-md-5">
                                                      <label>
                                                         <i className="fa-light fa-map-location-dot"></i> {t('step4.location')}
                                                      </label>
                                                   </div>
                                                   <div className="col-md-7">
                                                      <div className="data">
                                                         <span className="result_lan">{latitude}</span>, <span className="result_lng">{longitude}</span>
                                                      </div>
                                                   </div>
                                                </div>
                                             </li>
                                             <li className="list-group-item">
                                                <div className="row align-items-center">
                                                   <div className="col-md-5">
                                                      <label>
                                                         <i className="fa-solid fa-temperature-half"></i> {t('step4.temperature')}
                                                      </label>
                                                   </div>
                                                   <div className="col-md-7">
                                                      <div className="data">
                                                         Min. {minTemp}°, Max. {maxTemp}°
                                                      </div>
                                                   </div>
                                                </div>
                                             </li>
                                             <li className="list-group-item">
                                                <div className="row align-items-center">
                                                   <div className="col-md-5">
                                                      <label>
                                                         <i className="fa-light fa-solar-panel"></i> {t('step4.selected_model')}
                                                      </label>
                                                   </div>
                                                   <div className="col-md-7">
                                                      <div className="data">
                                                         {model.produttore} {model.modello}
                                                      </div>
                                                   </div>
                                                </div>
                                             </li>
                                             <li className="list-group-item">
                                                <div className="row align-items-center">
                                                   <div className="col-md-5">
                                                      <label>
                                                         <i className="fa-solid fa-transformer-bolt"></i> {t('step4.selected_inverters')}
                                                      </label>
                                                   </div>
                                                   <div className="col-md-7">
                                                      <div className="data">
                                                         {inverters.map(el => el.modello).join(', ')}
                                                      </div>
                                                   </div>
                                                </div>
                                             </li>
                                          </ul>
                                       </div>
                                    </div>
                                    <div className="box configurazione mt-5">
                                       <header>
                                          <h3 className="text-center">{t('step4.results.title')}</h3>
                                       </header>
                                       <div className="data-box-body mt-4">
                                          <div className="configuration-result mt-5">
                                             {
                                                inverters.map((el, key) =>
                                                {
                                                   return <ResultTable key={key.toString()} model={model} inverter={el} moduleMinTemp={moduleMinTemp} moduleMaxTemp={moduleMaxTemp} activeButton={activeButton} handleSelectedReport={handleSelectedReport} buildReport={buildReport} />
                                                })
                                             }
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </section>
         </main>
         <Footer />
      </>
   )
}

export default Step4;