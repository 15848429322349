import { useCore } from "../Core";

import
{
    NavLink,
    useNavigate

} from "react-router-dom";

const DashboardMobileNav = () =>
{
    const {
        clearAll
    } = useCore();

    const navigate = useNavigate();

    const handleCreateNew = () => {
        clearAll();
        navigate('/');
    }

    return (
        <>
            <nav className="mobile-nav d-lg-none bg-primary">
                <ul className="reset-list d-flex justify-content-center align-items-stretch mb-0">
                    <li className="d-flex flex-column px-2 col-4">
                        <NavLink to='/admin/' end className="reset-button text-white text-center d-flex flex-column flex-grow-1 px-2 align-items-stretch justify-content-center">
                            <span className="icon mb-1"><i className="fa-solid fa-user"></i></span>
                            <span className="label">Account</span>
                        </NavLink>
                    </li>
                    <li className="d-flex flex-column px-2 col-4">
                        <NavLink to='/admin/archive/' className="reset-button text-white text-center d-flex flex-column flex-grow-1 px-2 align-items-stretch justify-content-center">
                            <span className="icon mb-1"><i className="fa-solid fa-folder"></i></span>
                            <span className="label">Richieste</span>
                        </NavLink>
                    </li>
                    <li className="d-flex flex-column px-2 col-4">
                        <button onClick={() => handleCreateNew()} type="button" className="reset-button text-white text-center d-flex flex-column flex-grow-1 px-2 align-items-stretch justify-content-center">
                            <span className="icon mb-1"><i className="fa-solid fa-circle-plus"></i></span>
                            <span className="label">Nuova</span>
                        </button>
                    </li>
                </ul>
            </nav>
        </>
    )
}

export default DashboardMobileNav;