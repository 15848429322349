import { useTranslation } from 'react-i18next';
import { useState, useEffect } from 'react';
import { apiRoot } from '../settings';
import { useCore } from '../Core';

const EditModelModal = (props) =>
{

   const { model, onClose } = props;
   const { t } = useTranslation();

   const {
      isLoading,
      setIsLoading,
      setInverters,
      getProducers,
      setProducers,
      setProducer,
      setModels,
      getModels,
      setModel,
      token
   } = useCore();

   const [currentModel, setCurrentModel] = useState(model);

   console.log('currentModel', currentModel);

   useEffect(() =>
   {
      setCurrentModel(model);
   }, [model]);

   const handleValue = (event) =>
   {
      const value = event.target.value;
      const key = event.target.name;

      if (value.includes(','))
      {
         const searchRegExp = /,/gi;
         const replaceWith = '.';
         event.target.value = value.replace(searchRegExp, replaceWith);
      }

      setCurrentModel({ ...currentModel, [key]: value });
   }

   const updateModel = async (formData) =>
   {
      try{
         const headers = new Headers();
         headers.append("Content-Type", "application/json");
         headers.append("Authorization", `Token ${token}`);
   
         let formDataObject = {};
   
         formData.forEach((value, key) =>
         {
            if (key !== 'produttore' && key !== 'modello')
            {
               if( value.includes(',') ){
                  const searchRegExp = /,/gi;
                  const replaceWith = '.';
                  value = value.replace(searchRegExp, replaceWith);
              }
               value = parseFloat(value);
            }
            formDataObject[key] = value;
         });
   
         const updated = await fetch(`${apiRoot}/modules/custom/${model?.id}/`, {
            method: 'PUT',
            headers: headers,
            body: JSON.stringify(formDataObject)
         }).then(res => res.json());

         return updated;

      }catch(err){
         alert(err.message)
      }
      

   }

   const onSubmit = async (event) =>
   {
      event.preventDefault();

      console.log('SUBMIT');

      const formData = new FormData(event.target);

      const headers = new Headers();
      headers.append("Content-Type", "application/json");
      headers.append("Authorization", `Token ${token}`);

      const check = await fetch(`${apiRoot}/modules/custom/${model?.id}/check/`, {
         method: 'GET',
         headers: headers,
      }).then(res => res.json());

      if (!check.checked)
      {
         if (window.confirm(t('step2.model_details.edit_submit_button_confirm')))
         {
            await updateModel(formData).then(newModel => {
               getProducers().then(producers => {
                  getModels(newModel.produttore).then(updatedModels => {
                     setInverters([]);
                     setProducers(producers);
                     setProducer(newModel.produttore);
                     setModels(updatedModels);
                     setModel(newModel);
                  });
               });
            });

            onClose();
         }
      } else
      {
         await updateModel(formData).then(newModel => {
            getProducers().then(producers => {
               getModels(newModel.produttore).then(updatedModels => {
                  setInverters([]);
                  setProducers(producers);
                  setProducer(newModel.produttore);
                  setModels(updatedModels);
                  setModel(newModel);
               });
            });
         });
         
         onClose();
      }

   }

   return (
      <div className="modal new-module-modal fade" tabIndex="-1" role="dialog" id="editModuleModal">
         <div className="modal-dialog modal-lg">
            <div className="modal-content">
               <div className="modal-header">
                  <h5 className="modal-title">{t('step2.model_details.edit_module_title')}</h5>
                  <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
               </div>
               <div className="modal-body">
                  <form id="newModelForm" onSubmit={onSubmit}>
                     <input type="hidden" name="id" value={currentModel.id}/>
                     <div className="module-sheet">
                        <div className="body px-4">
                           <div className="product-info mt-4">
                              <table className="table table-bordered">
                                 <tbody>
                                    <tr>
                                       <td className="bg-primary text-white align-middle">
                                          <p className="mb-0">{t('step2.model_details.producer')}</p>
                                       </td>
                                       <td className="bg-light-grey">
                                          <input type="text" readOnly name="produttore" onChange={handleValue} value={currentModel.produttore} className="form-control" />
                                       </td>
                                    </tr>
                                    <tr>
                                       <td className="bg-primary text-white align-middle">
                                          <p className="mb-0">{t('step2.model_details.model')}</p>
                                       </td>
                                       <td className="bg-light-grey">
                                          <input readOnly type="text" name="modello" onChange={handleValue} value={currentModel.modello} className="form-control" />
                                       </td>
                                    </tr>
                                    <tr>
                                       <td className="bg-primary text-white align-middle">
                                          <p className="mb-0">Pnom[Wp]</p>
                                       </td>
                                       <td className="bg-light-grey">
                                          <input type="text" onChange={handleValue} name="p_nom" value={currentModel.p_nom} className="form-control" />
                                       </td>
                                    </tr>
                                    <tr>
                                       <td className="bg-primary text-white align-middle">
                                          <p className="mb-0">Vmp[V]</p>
                                       </td>
                                       <td className="bg-light-grey">
                                          <input type="text" onChange={handleValue} name="v_mp" value={currentModel.v_mp} className="form-control" />
                                       </td>
                                    </tr>
                                    <tr>
                                       <td className="bg-primary text-white align-middle">
                                          <p className="mb-0">Imp[A]</p>
                                       </td>
                                       <td className="bg-light-grey">
                                          <input type="text" onChange={handleValue} name="i_mp" value={currentModel.i_mp} className="form-control" />
                                       </td>
                                    </tr>
                                    <tr>
                                       <td className="bg-primary text-white align-middle">
                                          <p className="mb-0">Voc[V]</p>
                                       </td>
                                       <td className="bg-light-grey">
                                          <input type="text" onChange={handleValue} name="v_oc" value={currentModel.v_oc} className="form-control" />
                                       </td>
                                    </tr>
                                    <tr>
                                       <td className="bg-primary text-white align-middle">
                                          <p className="mb-0">Isc[A]</p>
                                       </td>
                                       <td className="bg-light-grey">
                                          <input type="text" onChange={handleValue} name="i_sc" value={currentModel.i_sc} className="form-control" />
                                       </td>
                                    </tr>
                                    <tr>
                                       <td className="bg-primary text-white align-middle">
                                          <p className="mb-0">MaxSysVoltage[V]</p>
                                       </td>
                                       <td className="bg-light-grey">
                                          <input type="text" onChange={handleValue} name="max_sys_v" value={currentModel.max_sys_v} className="form-control" />
                                       </td>
                                    </tr>
                                    <tr>
                                       <td className="bg-primary text-white align-middle">
                                          <p className="mb-0">VOC Temp Coef[%/C]</p>
                                       </td>
                                       <td className="bg-light-grey">
                                          <input type="text" onChange={handleValue} name="voc_temp_coef" value={currentModel.voc_temp_coef} className="form-control" />
                                       </td>
                                    </tr>
                                    <tr>
                                       <td className="bg-primary text-white align-middle">
                                          <p className="mb-0">Max system voltage</p>
                                       </td>
                                       <td className="bg-light-grey">
                                          <input type="text" onChange={handleValue} name="max_sys_v" value={currentModel.max_sys_v} className="form-control" />
                                       </td>
                                    </tr>
                                    <tr>
                                       <td className="bg-primary text-white align-middle">
                                          <p className="mb-0">ISC Temp Pmax [%/C]</p>
                                       </td>
                                       <td className="bg-light-grey">
                                          <input type="text" onChange={handleValue} name="isc_temp_pmax" value={currentModel.isc_temp_pmax} className="form-control" />
                                       </td>
                                    </tr>
                                    <tr>
                                       <td className="bg-primary text-white align-middle">
                                          <p className="mb-0">Pmax Temp Coef[%/C]</p>
                                       </td>
                                       <td className="bg-light-grey">
                                          <input type="text" onChange={handleValue} name="pmax_temp_coef" value={currentModel.pmax_temp_coef} className="form-control" />
                                       </td>
                                    </tr>
                                    <tr>
                                       <td className="bg-primary text-white align-middle">
                                          <p className="mb-0">{t('step2.model_details.length')} [mm]</p>
                                       </td>
                                       <td className="bg-light-grey">
                                          <input type="text" onChange={handleValue} name="lunghezza" value={currentModel.lunghezza} className="form-control" />
                                       </td>
                                    </tr>
                                    <tr>
                                       <td className="bg-primary text-white align-middle">
                                          <p className="mb-0">{t('step2.model_details.width')} [mm]</p>
                                       </td>
                                       <td className="bg-light-grey">
                                          <input type="text" onChange={handleValue} name="larghezza" value={currentModel.larghezza} className="form-control" />
                                       </td>
                                    </tr>
                                 </tbody>
                              </table>
                           </div>
                           <div className="cta text-center mt-5">
                              <button type="submit" className="new-module-form-submit btn btn-lg btn-primary btn-round">{t('step2.model_details.edit_submit_button')}</button>
                           </div>
                        </div>
                     </div>
                  </form>
               </div>
            </div>
         </div>
      </div>
   )
}
export default EditModelModal;