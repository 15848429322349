import logo from "../images/logo-westernco.png";
import { useCore } from "../Core";
import
{
    NavLink,
    useNavigate

} from "react-router-dom";
import { useTranslation } from 'react-i18next';

const DashboardNav = () =>
{
    const {
        handleLogout,
        clearAll
    } = useCore();

    const navigate = useNavigate();

    const { t } = useTranslation();

    const handleCreateNew = () => {
        clearAll();
        navigate('/');
    }

    return (
        <aside className="dashboard-sidebar d-flex flex-column sticky-top bg-primary text-white p-4 p-lg-5">
            <header className="d-flex flex-column align-items-center justify-content-center">
                <button type="button" className="reset-button" onClick={() => navigate('/')}>
                    <figure className="mb-0 logo">
                        <img alt={'Logo'} src={logo} loading="lazy" className="img-fluid" />
                    </figure>
                </button>
            </header>
            <div className="aside-body d-none d-lg-flex flex-lg-column flex-lg-grow-1">
                <nav>
                    <ul className="reset-list">
                        <li>
                            <NavLink to='/admin' end className="btn btn-round ps-0">
                                <span className="icon"><i className="fa-solid fa-user"></i></span>
                                <span className="label">{t('dashboard_nav.account_label')}</span>
                            </NavLink>
                        </li>
                        <li>
                            <NavLink to='/admin/archive' className="btn btn-round ps-0">
                                <span className="icon"><i className="fa-solid fa-folder"></i></span>
                                <span className="label">{t('dashboard_nav.archive_label')}</span>
                            </NavLink>
                        </li>
                        <li>
                            <button onClick={() => handleCreateNew()} type="button" className="btn btn-round ps-0">
                                <span className="icon"><i className="fa-solid fa-circle-plus"></i></span>
                                <span className="label">{t('dashboard_nav.new_request_labek')}</span>
                            </button>
                        </li>
                    </ul>
                </nav>
            </div>
            <footer className="p-5 d-none d-lg-block">
                <button type="button" className="btn btn-white btn-round text-dark" onClick={() => handleLogout()}>
                    <i className="fa-solid fa-arrow-right-from-bracket"></i> Logout
                </button>
            </footer>
        </aside>
    )
}

export default DashboardNav;