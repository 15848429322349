import { useTranslation } from "react-i18next";

const InverterDetailModal = (props) => {
    const {
        freq_uscita,
        i_fv_max,
        modello,
        p_fv_max,
        p_nom,
        sap_code,
        stringhe,
        ingressi_mppt,
        tensione_uscita,
        v_oc,
        v_startup
    } = props;

    const { t } = useTranslation();

    return (
        <div className="modal new-module-modal fade" tabIndex="-1" role="dialog" id="inverterDetailModal">
            <div className="modal-dialog modal-lg">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">{modello} Ac Output: {tensione_uscita}</h5>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body">
                        <table className="table table-sm">
                            <tbody>
                                <tr>
                                    <th  className="bg-primary text-white">{t('step3.inverter_details.model')}</th>
                                    <td className="text-end">{modello}</td>
                                </tr>
                                <tr>
                                    <th  className="bg-primary text-white">{t('step3.inverter_details.code')}</th>
                                    <td className="text-end">{sap_code}</td>
                                </tr>
                                <tr>
                                    <th  className="bg-primary text-white">Pnom[Wp]</th>
                                    <td className="text-end">{p_nom}</td>
                                </tr>
                                <tr>
                                    <th  className="bg-primary text-white">PfvMAX[W]</th>
                                    <td className="text-end">{p_fv_max}</td>
                                </tr>
                                <tr>
                                    <th  className="bg-primary text-white">Vstartup[V]</th>
                                    <td className="text-end">{v_startup}</td>
                                </tr>
                                <tr>
                                    <th  className="bg-primary text-white">Voc[V]</th>
                                    <td className="text-end">{v_oc}</td>
                                </tr>
                                <tr>
                                    <th  className="bg-primary text-white">IfvMAX</th>
                                    <td className="text-end">{i_fv_max}</td>
                                </tr>
                                <tr>
                                    <th  className="bg-primary text-white">N MPPT</th>
                                    <td className="text-end">{stringhe}</td>
                                </tr>
                                <tr>
                                    <th  className="bg-primary text-white">{t('step3.inverter_details.mppt_inputs')}</th>
                                    <td className="text-end">{ingressi_mppt}</td>
                                </tr>
                                <tr>
                                    <th  className="bg-primary text-white">{t('step3.inverter_details.ac_output_voltage')}</th>
                                    <td className="text-end">{tensione_uscita}</td>
                                </tr>
                                <tr>
                                    <th  className="bg-primary text-white">{t('step3.inverter_details.ac_output_frequency')}</th>
                                    <td className="text-end">{freq_uscita}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default InverterDetailModal;