import Header from '../components/Header';
import Footer from '../components/Footer';
import Toolbar from '../components/Toolbar';
import Intro from '../components/Intro';
import { Modal } from 'bootstrap';
import InverterCard from '../components/InverterCard';
import InverterDetailModal from '../components/InverterDetailModal';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useCore } from "../Core";
import { useNavigate, useParams } from 'react-router-dom';

const Step3 = () =>
{

    const { t } = useTranslation();
    const navigate = useNavigate();

    const {
      idConfigurazione
    } = useParams();

    const {
        isLoading,
        setIsLoading,
        invertersData,
        setInvertersData,
        inverterMode,
        setInverterMode,
        inverters,
        setInverters
    } = useCore();

    const [selectedInverter, setSelectedInverter] = useState({});
    const [mono, setMono] = useState(0);
    const [tri, setTri] = useState(0);
    const [hybrid, setHybrid] = useState(0);

    useEffect(() => {
        const modal = new Modal(document.getElementById('inverterDetailModal'),{backdrop:true});
    },[]);

    const handleMode = useCallback((event) => {
        setInverterMode(event.target.value);
    },[setInverterMode]);

    const getDetails = useCallback((data) => {
        const m = document.getElementById('inverterDetailModal');
        const mymodal = Modal.getInstance(m);
        setSelectedInverter(data);
        mymodal.show();
    },[]);

    const invertersMarkup = useCallback((data) => {
        
        const output = [];

        const filtered = data.filter(inv => {
            return inv.mono_tri === inverterMode;
        });

        const groupByMppt = filtered.reduce((group, item) => {
            const { stringhe } = item;
            group[stringhe] = group[stringhe] ?? [];
            group[stringhe].push(item);
            return group;
          }, {});

        if( Object.keys(groupByMppt).length )
        {
            let first_iteration = true;
            for( let i in groupByMppt )
            {
               const translationKey = i > 1 ? 'step3.mppt_header_plural' : 'step3.mppt_header';
                const html = (
                    <div className={`inverters-mppt-group inverters-mppt-group-${i}${!first_iteration ? ' mt-5':''}`} key={i.toString()}>
                        <h3 className='text-start mb-4 group-heading'>{t(translationKey, { count: i })}</h3>
                        <div className='inverters-mppt-group-wrapper inverters-grid g-0 justify-content-center '>
                            {groupByMppt[i].map((el, key) => {
                                return <InverterCard key={key.toString()} {...el} getDetails={getDetails}/>
                            })}
                        </div>
                    </div>
                );
                first_iteration = false;
                output.push(html);
            }
        }

        return <>{output}</>

    },[inverterMode]);

    useEffect(() =>
    {
        const countMono = inverters.filter((el) => { return el.mono_tri === 'M' }).length;
        const countTri = inverters.filter((el) => { return el.mono_tri === 'T' }).length;
        const countHybrid = inverters.filter((el) => { return el.mono_tri === 'H' }).length;

        setMono(countMono);
        setTri(countTri);
        setHybrid(countHybrid);
        
    }, [inverters]);

    const countMono = useCallback(() => {
        return inverters.filter((el) => { return el.mono_tri === 'M' }).length;
    },[inverters]);

    const handleNextStep = () =>
   {
      const nextStepUrl = idConfigurazione ? `/edit/${idConfigurazione}/step-4` : '/step-4';
      navigate(nextStepUrl);
   }

   const handlePrevStep = () =>
   {
      const prevStepUrl = idConfigurazione ? `/edit/${idConfigurazione}/step-2` : '/step-2';
      navigate(prevStepUrl)
   }

    return (
        <>
            <Header />
            <main className="app-mai bg-white">
                <section className="wizard">
                    <Toolbar title={t('toolbar.title')} subtitle={t('toolbar.subtitle')} activeStep={3} />
                    <div className="step-content">
                        <div className="container-fluid px-0 step step-3 bg-lighest">
                            <div className="row g-0 align-items-start">
                                <div className="layout-column first bg-white col-lg-4 d-flex flex-column bg-white sticky-top">
                                    <Intro icon="fa-solid fa-transformer-bolt" title={t('step3.intro.title')} subtitle={t('step3.intro.subtitle')}>
                                        <div className="info-action">
                                            <p><button disabled={!inverters.length > 0} onClick={() => handleNextStep()} className="btn btn-primary btn-next btn-round px-3">Continua</button></p>
                                            <p><button onClick={() => handlePrevStep()} className="reset-button text-decoration-underline text-dark text-small">{t('general.back')}</button></p>
                                        </div>
                                    </Intro>
                                </div>
                                <div className="layout-column last col-lg-8 px-3 py-5 px-lg-5 d-flex flex-column justify-content-center">
                                    <div className="wrapper">
                                        <div className="d-flex g-0 justify-content-center">
                                            <div className="inverter-type-selection d-inline-flex">
                                                <input checked={inverterMode === 'M'} onChange={handleMode} className="d-none" type="radio" name="inverter-type" id="monofase" value="M" />
                                                <label className="mb-0 text-nowrap" htmlFor="monofase">
                                                    <span className='text-nowrap'>{t('step3.types.monofase')}</span>
                                                    {mono > 0 && <span className='badge'>{mono}</span>}
                                                </label>
                                                <input checked={inverterMode === 'T'} onChange={handleMode} className="d-none" type="radio" name="inverter-type" id="trifase" value="T" />
                                                <label className="mb-0 text-nowrap" htmlFor="trifase">
                                                    <span className='text-nowrap'>{t('step3.types.trifase')}</span>
                                                    {tri > 0 && <span className='badge'>{tri}</span>}
                                                </label>
                                                <input checked={inverterMode === 'H'} onChange={handleMode} className="d-none" type="radio" name="inverter-type" id="ibridi" value="H" />
                                                <label className="mb-0 text-nowrap" htmlFor="ibridi">
                                                    <span className='text-nowrap'>{t('step3.types.ibrido')}</span>
                                                    {hybrid > 0 && <span className='badge'>{hybrid}</span>}
                                                </label>
                                            </div>
                                        </div>
                                        <div className="d-flex justify-content-center">
                                            <div className="mt-5">
                                                {invertersData && invertersMarkup(invertersData)}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
            <Footer/>
            <InverterDetailModal {...selectedInverter}/>
        </>
    )
}

export default Step3;