import Header from '../components/Header';
import Footer from '../components/Footer';
import Toolbar from '../components/Toolbar';
import Intro from '../components/Intro';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useCore } from "../Core";
import { useNavigate, useLocation, Navigate } from 'react-router-dom';
import logo from '../images/logo-Western-Retina-6.png';

const Report = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const location = useLocation();

    const [params, setParams] = useState({});

    const {
        isLoading,
        setIsLoading,
        inverters,
        result,
        setResult,
        models,
        selectedReport,
        setSelectedReport,
        token,
    } = useCore();

    if(!Object.keys(selectedReport).length)
    {
        return <Navigate to="/" />;
    }

    const {
        inverter,
        model,
        data,
        latitude,
        longitude,
        minTemp,
        maxTemp,
        moduleMinTemp,
        moduleMaxTemp
    } = selectedReport;

    const handlePrint = () => {
        window.print();
    }

    const getMonoTri = (monotri) =>
    {
        let output= '';
        switch( monotri )
        {
            case 'M':
                output = 'Monofase';
                break;
            case 'T':
                output = 'Trifase';
                break;
            case 'H':
                output = 'Ibrido';
                break;
            default:
                break;
        }
        return output;
    }

    const mpptMarkup = () => {
        
        const t = [
            {
                label: "Pnom[Wp]",
                value: inverter.p_nom
            },
            {
                label: "PfvMAX[W]",
                value: inverter.p_fv_max
            },
            {
                label: "Vstartup[V]",
                value: inverter.v_startup
            },
            {
                label: "Voc[V]",
                value: inverter.v_oc
            },
            {
                label: "IfvMAX",
                value: inverter.i_fv_max
            },
            {
                label: "Tensione Uscita AC [V]",
                value: inverter.tensione_uscita
            },
            {
                label: "Frequenza Uscita AC [Hz]",
                value: inverter.freq_uscita
            }
        ];
        return t.map((tr, k) => {
            return (
                <tr key={k.toString()}>
                    <td>{tr.label}</td>
                    {data.map((td, i) => {
                        return <td key={i.toString()} className="text-center">{tr.value}</td>
                    })}
                </tr>
            )
        });
    }

    console.log('selectedReport.data', data);
    
    return (
        <>
        <Header/>
        <main className="app-mai bg-white">
            <section className="wizard">
                <Toolbar title={t('toolbar.title')} subtitle={t('toolbar.subtitle')} displaySteps={false} />
                <div className="step-content">
                <div className="container-fluid px-0 step step-4 bg-lighest">
                    <div className="row g-0">
                        <div className="layout-column last col-lg-8 offset-lg-2 p-4 p-md-5 d-flex flex-column justify-content-start">
                            <div className="wrapper">
                                <div className="full-container">
                                    <div className="boxed bg-white py-5 px-4">
                                        <div className='report-logo d-flex justify-content-end'>
                                            <figure>
                                                <img src={logo} width={250}/>
                                            </figure>
                                        </div>
                                        <div className="box report">
                                            <header>
                                                <h3 className="text-center report-heading">Report di configurazione</h3>
                                            </header>
                                            <div className="data-box-body mt-4">
                                                <div className='report-container'>
                                                    <div className='row g-0'>
                                                        <div className='col-lg-12'>
                                                            <div className='table-responsive'>
                                                            <table className='layout-table w-100 table-report'>
                                                                <tbody>
                                                                    {/* Row 1 */}
                                                                    <tr className='tr-1'>
                                                                        <td>
                                                                            <table className='w-100'>
                                                                                <tbody>
                                                                                    <tr>
                                                                                        <td className='col-6 pe-3'>
                                                                                            <table className='table table-sm'>
                                                                                                <thead colSpan={2} className="table-heading">
                                                                                                    <tr>
                                                                                                        <th colSpan={2}>Coordinate</th>
                                                                                                    </tr>
                                                                                                </thead>
                                                                                                <tbody>
                                                                                                    <tr>
                                                                                                        <td className='text-start'>Latitudine:</td>
                                                                                                        <td className='text-end'>{latitude}</td>
                                                                                                    </tr>
                                                                                                    <tr>
                                                                                                        <td className='text-start'>Longitudine:</td>
                                                                                                        <td className='text-end'>{longitude}</td>
                                                                                                    </tr>
                                                                                                </tbody>
                                                                                            </table>
                                                                                        </td>
                                                                                        <td className='col-6 ps-3'>
                                                                                            <table className='table table-sm'>
                                                                                                <thead className="table-heading">
                                                                                                    <tr>
                                                                                                        <th className='text-start'>Temperatura</th>
                                                                                                        <th className='text-end'>Amb</th>
                                                                                                        <th className='text-end'>Cell</th>
                                                                                                    </tr>
                                                                                                </thead>
                                                                                                <tbody>
                                                                                                    <tr>
                                                                                                        <td className='text-start'>Minima:</td>
                                                                                                        <td className='text-end'>{minTemp} °C</td>
                                                                                                        <td className='text-end'>{moduleMinTemp}°C</td>
                                                                                                    </tr>
                                                                                                    <tr>
                                                                                                        <td className='text-start'>Massima:</td>
                                                                                                        <td className='text-end'>{maxTemp}°C</td>
                                                                                                        <td className='text-end'>{moduleMaxTemp}°C</td>
                                                                                                    </tr>
                                                                                                </tbody>
                                                                                            </table>
                                                                                        </td>
                                                                                    </tr>
                                                                                </tbody>
                                                                            </table>
                                                                        </td>
                                                                    </tr>
                                                                    {/* Row 3 */}
                                                                    {Object.keys(model).length > 0 && (
                                                                    <>
                                                                    <tr className='tr-2'>
                                                                        <td height={20}>
                                                                            
                                                                        </td>
                                                                    </tr>
                                                                    <tr className='tr-3'>
                                                                        <td className='col'>
                                                                            <table className='table table-sm'>
                                                                                <thead className='table-heading'>
                                                                                    <tr>
                                                                                        <th colSpan={2}>Modulo Fotovoltaico</th>
                                                                                    </tr>
                                                                                </thead>
                                                                                <tbody>
                                                                                    <tr>
                                                                                        <td className='text-start'>Produttore</td>
                                                                                        <td className='text-end'>{model.produttore}</td>
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <td className='text-start'>Modello</td>
                                                                                        <td className='text-end'>{model.modello}</td>
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <td className='text-start'>Pnom[Wp]</td>
                                                                                        <td className='text-end'>{model.p_nom}</td>
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <td className='text-start'>Vmp[V]</td>
                                                                                        <td className='text-end'>{model.v_mp}</td>
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <td className='text-start'>Imp[A]</td>
                                                                                        <td className='text-end'>{model.i_mp}</td>
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <td className='text-start'>Voc[V]</td>
                                                                                        <td className='text-end'>{model.v_oc}</td>
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <td className='text-start'>Isc[A]</td>
                                                                                        <td className='text-end'>{model.i_sc}</td>
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <td className='text-start'>MaxSysVoltage[V]</td>
                                                                                        <td className='text-end'>{model.max_sys_v}</td>
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <td className='text-start'>VOC Temp Coef[%/C]</td>
                                                                                        <td className='text-end'>{model.voc_temp_coef}</td>
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <td className='text-start'>ISC Temp Pmax [%/C]</td>
                                                                                        <td className='text-end'>{model.isc_temp_pmax}</td>
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <td className='text-start'>Pmax Temp Coef[%/C]</td>
                                                                                        <td className='text-end'>{model.pmax_temp_coef}</td>
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <td className='text-start'>Lunghezza [mm]</td>
                                                                                        <td className='text-end'>{model.lunghezza}</td>
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <td className='text-start'>Larghezza [mm]</td>
                                                                                        <td className='text-end'>{model.larghezza}</td>
                                                                                    </tr>
                                                                                </tbody>
                                                                            </table>
                                                                        </td>
                                                                    </tr>
                                                                    </>
                                                                    )}
                                                                    {/* Row 4 */}
                                                                    {Object.keys(inverter).length > 0 && (
                                                                    <>
                                                                    <tr className='tr-2'>
                                                                        <td height={20}>
                                                                            
                                                                        </td>
                                                                    </tr>
                                                                    <tr className='tr-3'>
                                                                        <td className='col'>
                                                                            <table className='table table-sm'>
                                                                                <thead className='table-heading'>
                                                                                    <tr>
                                                                                        <th colSpan={2}>Inverter</th>
                                                                                    </tr>
                                                                                </thead>
                                                                                <tbody>
                                                                                    <tr>
                                                                                        <td className='text-start'>Codice Western</td>
                                                                                        <td className='text-end'>{inverter.sap_code}</td>
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <td className='text-start'>Modello</td>
                                                                                        <td className='text-end'>{inverter.modello}</td>
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <td className='text-start'>Monofase/Trifase</td>
                                                                                        <td className='text-end'>{getMonoTri(inverter.mono_tri)}</td>
                                                                                    </tr>
                                                                                </tbody>
                                                                            </table>
                                                                        </td>
                                                                    </tr>
                                                                    
                                                                    <tr className='tr-2'>
                                                                        <td height={20}>
                                                                            
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td colSpan={2}>
                                                                            <table className='table table-sm mt-1 table-bordered table-layout-fixed'>
                                                                                <thead>
                                                                                    <tr>
                                                                                        <th className='text-center text-white bg-primary'></th>
                                                                                        {data.map((el, i) => {
                                                                                            return (<th key={i.toString()} className='text-center text-white bg-primary'>MPPT{el.mppt}</th>)
                                                                                        })}
                                                                                    </tr>
                                                                                </thead>
                                                                                <tbody>
                                                                                    <tr>
                                                                                        <td className='text-start'>Moduli per stringa</td>
                                                                                        {data.map((el, i) => {
                                                                                            return (
                                                                                                <td key={i.toString()} className='text-center'>{el.serie}</td>
                                                                                            )
                                                                                        })}
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <td className='text-start'>Numero stringhe in parallelo</td>
                                                                                        {data.map((el, i) => {
                                                                                            return (
                                                                                                <td key={i.toString()} className='text-center'>{el.strigs}</td>
                                                                                            )
                                                                                        })}
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <td className='text-start'>Totale pannelli</td>
                                                                                        {data.map((el, i) => {
                                                                                            return (
                                                                                                <td key={i.toString()} className='text-center'>{el.strigs * el.serie}</td>
                                                                                            )
                                                                                        })}
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <td className='text-start'>Potenza STC installata MPPT [kW]</td>
                                                                                        {data.map((el, i) => {
                                                                                            let output;
                                                                                            if( el.valid === 1 )
                                                                                            {
                                                                                                output = (<td key={i.toString()} className={`text-center success`}><strong>{el.value/1000}</strong></td>);
                                                                                            }else{
                                                                                                const disclaimer = el.status === -1 ? 'Attenzione: Potenza al di sotto del 90% di quella raccomandata':'Attenzione: Possibilità di limitazione potenza in uscita';
                                                                                                output = (<td key={i.toString()} className={`text-center warning`}><strong>{el.value/1000}</strong>
                                                                                                <span className='text-danger'>*</span>
                                                                                                <p className='mb-0 mt-1'><small>{disclaimer}</small></p>
                                                                                                </td>)
                                                                                            }
                                                                                            return output;
                                                                                        })}
                                                                                    </tr>
                                                                                    {mpptMarkup()}
                                                                                </tbody>
                                                                            </table>
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td colSpan={2} className="text-end text-small">
                                                                            <table className='table mt-4'>
                                                                                <tbody>
                                                                                    <tr>
                                                                                        <td>
                                                                                            <strong>
                                                                                            Tot. numero moduli: {data.reduce((accumulator, ob) => {
                                                                                                return accumulator + ob.totalPanels;
                                                                                            }, 0)}
                                                                                            </strong>
                                                                                        </td>
                                                                                    </tr>
                                                                                </tbody>
                                                                            </table>
                                                                        </td>
                                                                    </tr>
                                                                    </>
                                                                    )}
                                                                </tbody>
                                                            </table>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='row g-0 mt-4 print-cta'>
                                                        <div className='col text-center'>
                                                            <button onClick={() => handlePrint()} className='btn btn-primary text-white'>STAMPA REPORT</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            </section>
        </main>
        <Footer/>
        </>
    )
}

export default Report;