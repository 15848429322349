import logo from '../images/logo-westernco.png';
import it_flag from '../images/it.png';
import en_flag from '../images/en.png';
import { useCore } from "../Core";
import { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

const Footer = () => {
    const { t, i18n } = useTranslation();

    const {
        appData,
        setAppData,
        language,
        setLanguage
    } = useCore();

    useEffect(() => {
        i18n.changeLanguage(language);
    },[language]);

    const handleLanguageChange = useCallback((lang) => {
        //setAppData({...appData, lang: lang});
        setLanguage(lang);
    },[]);

    return (
        <footer className="app-footer">
            <div className="logo">
                <img src={logo} className="img-fluid" alt="Western CO." loading="lazy"/>
            </div>
            <div className="text mb-4">
                <p className="mb-4">via Pasubio 1/3<br/>63074 San Benedetto del Tronto (AP)<br/>{t('footer.italy')}</p>
                <p><a href="mailto:info@western.it">info@western.it</a><br/>(+39) 0735 751248</p>
            </div>
            <div className="text-small">
                <p>Copyright © WESTERN CO. - {t('footer.rights')} | {t('footer.vat')}: 01192450441 | Cap. Soc. 187.398,00 € I.V.<br/>
                <a href="https://www.iubenda.com/privacy-policy/92234546" rel="noreferrer" target="_blank">Privacy Policy</a> - <a href="https://www.iubenda.com/privacy-policy/92234546/cookie-policy" rel="noreferrer" target="_blank">Cookie Policy</a></p>
            </div>
            <div className='language-switcher'>
                <button onClick={() => handleLanguageChange('it')}>
                    <img src={it_flag} width="24" className={`img-fluid${i18n.language === 'it' ? ' current-lang':''}`} alt="Italiano"/>
                </button>
                <button onClick={() => handleLanguageChange('en')}>
                <img src={en_flag} width="24" className={`img-fluid${i18n.language === 'en' ? ' current-lang':''}`} alt="English"/>
                </button>
            </div>
        </footer>
    )
}
export default Footer;