import backgroundImage from "../images/login-bg-image.jpeg";
import logo from "../images/logo-westernco.png";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import { useCore } from "../Core";
import { apiRoot } from "../settings";
import { useForm } from "react-hook-form";
import { useNavigate, Navigate } from 'react-router-dom';
const Recover = () =>
{
    const { register, formState: { errors }, handleSubmit } = useForm();

    const { t, i18n } = useTranslation();
    const navigate = useNavigate();

    const {
        isLoading,
        setIsLoading,
        user,
        setUser,
        token,
        setToken,
        language,
        setLanguage
    } = useCore();

    useEffect(() => {
        i18n.changeLanguage(language);
    },[language]);

    const [email, setEmail] = useState('');

    const handleEmail = useCallback((e) => {
        setEmail(e.target.value);
    },[]);

    const handleRecover = useCallback(async (data, event) => {
        event.preventDefault();
        setIsLoading(true);

        const formData = new FormData(event.target);

        const response = await fetch(
            `${apiRoot}/auth/reset-password/`,
            {
                method: 'POST',
                body: formData
            }
        ).then(rsp => rsp.json())
        .catch(error => console.log(error.message));
        
        if( 'error' === response.result )
        {
            setIsLoading(false);
            alert(response.message);
            return false;
        }
        setIsLoading(false);
        navigate('/admin/account/login', { state: { recover: true } });
        return false;
    },[]);

    return (
        <>
            <div className="login-wrapper" style={{ backgroundImage: `url(${backgroundImage})` }}>
                <div className="login">
                    <div className="row g-0">
                        <div className="column col-lg-5 first p-5 bg-primary text-white d-flex flex-column justify-content-center">
                            <button type="button" className="reset-button" onClick={() => navigate('/')}>
                                <figure className="mb-0">
                                    <img src={logo} alt="logo" loading="lazy" className="img-fluid"/>
                                </figure>
                            </button>
                            <div className="login-welcome text-center mt-4 mt-lg-5">
                                <p className="mb-0">{t('recover.welcome_message')}</p>
                                <div className="mt-3 mt-lg-4">
                                    <button type="button" className="btn btn-white text-dark btn-round px-4" onClick={() => navigate('/admin/account/login')}>{t('recover.back_button')}</button>
                                </div>
                            </div>
                        </div>
                        <div className="column p-5 col-lg bg-white last">
                            <form id="recover-form" method="POST" onSubmit={handleSubmit(handleRecover)}>
                                <header className="pb-4">
                                    <h3 className="lh-1 pb-3 border-bottom"><span>{t('recover.form.submit_label')}</span></h3>
                                </header>
                                <div className="login-body">
                                    <div className="input-wrapper">
                                        <label htmlFor="recover-email" className="mb-1">E-mail<span className="required text-primary">*</span></label>
                                        <div className="field">
                                            <input type="email" id="recover-email" onChange={handleEmail} name="username" {...register("username", { required: true, value: email, pattern: /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/})} placeholder={t('recover.form.placeholders.email')} className="form-control py-2"/>
                                            {errors.email && <span className="validation-error">{t('recover.form.errors.email')}</span>}
                                        </div>
                                    </div>
                                    <div className="input-wrapper mt-4 d-flex flex-column flex-lg-row align-items-stretch justify-content-lg-between align-items-lg-center">
                                        <button className="btn btn-primary btn-round px-4" disabled={isLoading} type="submit"><span>{t('recover.form.submit_label')}</span></button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default Recover;