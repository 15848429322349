import { useCallback, useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import { useCore } from "../Core";
import { apiRoot } from "../settings";
import { useForm } from "react-hook-form";
import { useNavigate, Navigate, useLocation } from 'react-router-dom';
import backgroundImage from "../images/login-bg-image.jpeg";
import logo from "../images/logo-westernco.png";
const Login = () => {

    const { register, formState: { errors }, handleSubmit } = useForm();
    const { state } = useLocation();

    const { t, i18n } = useTranslation();
    const navigate = useNavigate();

    //const [email, setEmail] = useState('');
    //const [password, setPassword] = useState('');

    const {
        isLoading,
        setIsLoading,
        user,
        setUser,
        token,
        setToken,
        language,
        setLanguage
    } = useCore();

    useEffect(() => {
        i18n.changeLanguage(language);
    },[language]);

    const switchToRegister = useCallback(() => {
        navigate('/admin/account/register');
    },[]);

    const displayAlert = () => {
        console.log('state', state);
        if( state !== null && state.recover )
        {
            return <div className="alert alert-success">{t('login.alert_after_recover')}</div>
        }
        return;
    }

    const handleLogin = async (data, event) => {
        event.preventDefault();
        setIsLoading(true);
        const formData = new FormData(event.target);

        const response = await fetch(
            `${apiRoot}/auth/login/`,
            {
                method: 'POST',
                body: formData
            }
        ).then(rsp => rsp.json())
        .catch(error => console.log('ERRORE', error.message));
        
        if( 'error' === response.result )
        {
            setIsLoading(false);
            alert(response.message);
            return false;
        }
        setUser(response);
        setToken(response.token);
        setIsLoading(false);
        navigate('/admin');
        return false;
    }

    if(token)
    {
        return <Navigate to="/admin" />;
    }

    return (
        <>
        <div className="login-wrapper" style={{backgroundImage: `url(${backgroundImage})`}}>
            <div className="login">
                <div className="row g-0">
                    <div className="column col-lg-5 first p-5 bg-primary text-white d-flex flex-column justify-content-center">
                        <button type="button" className="reset-button" onClick={() => navigate('/')}>
                            <figure className="mb-0">
                                <img src={logo} alt="logo" loading="lazy" className="img-fluid"/>
                            </figure>
                        </button>
                        <div className="login-welcome text-center mt-4 mt-lg-5">
                            <p className="mb-0" dangerouslySetInnerHTML={{__html: t('login.welcome_message')}}></p>
                            <div className="already-registered mt-3 mt-lg-4">
                                <button type="button" className="btn btn-white text-dark btn-round px-4" onClick={switchToRegister}>{t('login.no_account_message')}</button>
                            </div>
                        </div>
                    </div>
                    <div className="column p-5 col-lg bg-white last">
                        <form id="login-form" method="POST" onSubmit={handleSubmit(handleLogin)} autoComplete="off" noValidate>
                            <header className="pb-4">
                                <h3 className="lh-1 pb-3 border-bottom"><span>Login</span></h3>
                                {displayAlert()}
                            </header>
                            <div className="login-body">
                                <div className="input-wrapper">
                                    <label htmlFor="login-email" className="mb-1">E-mail<span className="required text-primary">*</span></label>
                                    <div className="field position-relative">
                                        <input type="email" id="login-email" name="username" {...register("username", { required: true })} placeholder={t('login.form.placeholders.email')} className="form-control py-2"/>
                                        {errors.email && <span className="validation-error">{t('login.form.errors.email')}</span>}
                                    </div>
                                </div>
                                <div className="input-wrapper mt-4">
                                    <label htmlFor="login-password" className="mb-1">Password<span className="required text-primary">*</span></label>
                                    <div className="field position-relative">
                                        <input type="password" id="login-password" name="password" {...register("password", { required: true, minLength: 8})} placeholder={t('login.form.placeholders.password')}className="form-control py-2"/>
                                        {errors.password && <span className="validation-error">{t('login.form.errors.password')}</span>}
                                    </div>
                                </div>
                                <div className="input-wrapper mt-5 d-flex flex-column flex-lg-row align-items-stretch justify-content-lg-between align-items-lg-center">
                                    <button className="btn btn-primary btn-round px-4" type="submit"><span>{t('login.form.submit_label')}</span></button>
                                    <button onClick={() => navigate('/admin/account/recover')} type="button" className="forgot reset-button d-block d-lg-inline-flex text-primary text-xsmall text-center lh-1 mt-4 mt-lg-0 text-decoration-underline">{t('login.form.forgot_password')} <i className="fa-solid fa-lock ps-1"></i></button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
        </>
    )
}
export default Login;