import { useCallback, useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import { useCore } from "../Core";
import { apiRoot } from "../settings";
import { useNavigate, Navigate } from 'react-router-dom';
import backgroundImage from "../images/login-bg-image.jpeg";
import logo from "../images/logo-westernco.png";
//import Dialog from "../components/Dialog";

const Register = () => {

    const { t, i18n } = useTranslation();
    const navigate = useNavigate();

    const {
        isLoading,
        setIsLoading,
        token,
        language,
        setLanguage
    } = useCore();

    useEffect(() => {
        i18n.changeLanguage(language);
    },[language]);

    const [nome, setNome] = useState('');
    const [cognome, setCognome] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirm, setConfirm] = useState('');

    const handleChangeName = useCallback((e) => {
        setNome(e.target.value);
    },[]);

    const handleChangeLastname = useCallback((e) => {
        setCognome(e.target.value);
    },[]);

    const handleChangeEmail = useCallback((e) => {
        setEmail(e.target.value);
    },[]);

    const handleChangePassword = useCallback((e) => {
        setPassword(e.target.value);
    },[]);

    const handleChangeConfirm = useCallback((e) => {
        setConfirm(e.target.value);
    },[]);

    const handleRegistration = useCallback(async (e) => {
        e.preventDefault();
        setIsLoading(true);
        const formData = new FormData(e.target);
        const response = await fetch(
            `${apiRoot}/auth/register/`,
            {
                method: 'POST',
                body: formData
            }
        ).then(rsp => rsp.json())
        .catch(error => console.log(error.message));

        console.log('response', response);
        
        if( 'error' === response.result )
        {
            setIsLoading(false);
            alert(response.message);
            return false;
        }

        navigate('/admin/account/login');
        
        return false;
    },[navigate, setIsLoading]);

    const switchToLogin = useCallback(() => {
        navigate('/admin/account/login');
    },[navigate]);

    if(token)
    {
        return <Navigate to="/admin" />;
    }

    return (
        <>
            <div className="login-wrapper" style={{backgroundImage: `url(${backgroundImage})`}}>
                <div className="login">
                    <div className="row g-0">
                        <div className="column col-lg-5 first p-5 bg-primary text-white d-flex flex-column justify-content-center">
                            <button type="button" className="reset-button" onClick={() => navigate('/')}>
                                <figure className="mb-0">
                                    <img src={logo} alt="logo" loading="lazy" className="img-fluid"/>
                                </figure>
                            </button>
                            <div className="login-welcome text-center mt-4 mt-lg-5">
                                <p className="mb-0" dangerouslySetInnerHTML={{ __html: t('register.welcome') }}></p>
                                <div className="already-registered mt-3 mt-lg-4">
                                    <button type="button" onClick={switchToLogin} className="btn btn-white text-dark btn-round px-4">{t('register.already_registered')}</button>
                                </div>
                            </div>
                        </div>
                        <div className="column p-5 col-lg bg-white last">
                            <form id="registration-form" method="POST" onSubmit={handleRegistration}>
                                <header className="pb-4">
                                    <h3 className="lh-1 pb-3 border-bottom"><span>{t('register.form.title')}</span></h3>
                                </header>
                                <div className="login-body">
                                    <div className="input-wrapper">
                                        <label htmlFor="register-name" className="mb-1">{t('register.form.labels.firstname')}<span className="required text-primary">*</span></label>
                                        <div className="field">
                                            <input type="text" readOnly={isLoading} name="nome" id="login-name" onChange={handleChangeName} placeholder={t('register.form.placeholders.firstname')} value={nome} className="form-control py-2"/>
                                        </div>
                                    </div>
                                    <div className="input-wrapper mt-3">
                                        <label htmlFor="register-lastname" className="mb-1">{t('register.form.labels.lastname')}<span className="required text-primary">*</span></label>
                                        <div className="field">
                                            <input type="text" readOnly={isLoading} name="cognome" id="login-lastname" onChange={handleChangeLastname} placeholder={t('register.form.placeholders.lastname')} value={cognome} className="form-control py-2"/>
                                        </div>
                                    </div>
                                    <div className="input-wrapper mt-3">
                                        <label htmlFor="register-email" className="mb-1">{t('register.form.labels.email')}<span className="required text-primary">*</span></label>
                                        <div className="field">
                                            <input type="email" readOnly={isLoading} name="email" value={email} id="register-email" onChange={handleChangeEmail} placeholder={t('register.form.placeholders.email')} className="form-control py-2"/>
                                        </div>
                                    </div>
                                    <div className="input-wrapper mt-3">
                                        <label htmlFor="register-password" className="mb-1">{t('register.form.labels.password')}<span className="required text-primary">*</span></label>
                                        <div className="field">
                                            <input type="password" readOnly={isLoading} name="password" value={password} onChange={handleChangePassword} placeholder={t('register.form.placeholders.password')} className="form-control py-2"/>
                                        </div>
                                    </div>
                                    <div className="input-wrapper mt-3">
                                        <label htmlFor="register-password-confirm" className="mb-1">{t('register.form.labels.confirm')}<span className="required text-primary">*</span></label>
                                        <div className="field">
                                            <input type="password" readOnly={isLoading} name="confirm-password" value={confirm} onChange={handleChangeConfirm} placeholder={t('register.form.placeholders.confirm')} className="form-control py-2"/>
                                        </div>
                                    </div>
                                    <div className="input-wrapper mt-4 d-flex flex-column flex-lg-row align-items-stretch justify-content-lg-start align-items-lg-center">
                                        <button disabled={isLoading} className="btn btn-primary btn-round px-4" type="submit"><span>{t('register.form.submit_label')}</span></button>{ isLoading && (<div className="ms-2"><div className="small-spinner"></div></div>) }
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default Register;