import
{
    // BrowserRouter,
    Routes,
    Route,
    Navigate
} from "react-router-dom";
import { useCore } from "./Core";
import Step1 from './pages/Step1';
import Step2 from './pages/Step2';
import Step3 from './pages/Step3';
import Step4 from './pages/Step4';
import Admin from "./pages/Admin";
import Login from "./pages/Login";
import Register from "./pages/Register";
import Recover from "./pages/Recover";
import Account from "./pages/Account";
import Archive from "./pages/Archive";
//import Forgot from "./pages/Forgot";
import Page404 from "./pages/Page404";
import Report from "./pages/Report";
import ConfigurationItem from "./pages/ConfigurationItem";
import { useEffect } from "react";

//import { useEffect } from "react";
function App()
{
    const {
        language,
        moduleMinTemp,
        moduleMaxTemp,
        inverters,
        model,
        // user,
        // selectedReport,
        // token
    } = useCore();

    // useEffect(() => {
        
    // },[]);

    return (
        <Routes>
            <Route path="" element={<Step1 />} />
            <Route path="edit/:idConfigurazione" element={<Step1 />} />
            <Route path="step-2" element={(!Object.keys(model).length) ? <Navigate replace to="/" /> : <Step2 />} />
            <Route path="edit/:idConfigurazione/step-2" element={(!Object.keys(model).length) ? <Navigate replace to="/" /> : <Step2 />} />
            <Route path="step-3" element={((!moduleMinTemp || !moduleMaxTemp) ? <Navigate replace to="/" /> : <Step3 />)} />
            <Route path="edit/:idConfigurazione/step-3" element={((!moduleMinTemp || !moduleMaxTemp) ? <Navigate replace to="/" /> : <Step3 />)} />
            <Route path="step-4" element={((!inverters.length) ? <Navigate replace to="/" /> : <Step4 />)} />
            <Route path="edit/:idConfigurazione/step-4" element={((!inverters.length) ? <Navigate replace to="/" /> : <Step4 />)} />
            <Route path="print-report" element={<Report />} />
            <Route path="admin/account/register" element={<Register />} />
            <Route path="admin/account/login" element={<Login />} />
            <Route path="admin/account/recover" element={<Recover />} />
            <Route path="admin" element={<Admin />}>
                <Route index element={<Account />} />
                <Route index path="account" element={<Account />} />
                <Route path="archive" element={<Archive />} />
                <Route path="archive/:id" element={<ConfigurationItem />} />
                <Route path="*" element={<Page404 />} />
            </Route>
        </Routes>
    );
}

export default App;