import { useCore } from "../Core";
import { useForm } from "react-hook-form";
import { apiRoot } from "../settings";
import { useTranslation } from 'react-i18next';
import BsAlert from "../components/BsAlert";
import { useCallback, useState } from "react";
const Account = () =>
{
    const {
        isLoading,
        setIsLoading,
        user,
        setUser
    } = useCore();

    const { t } = useTranslation();

    const { register, formState: { errors }, handleSubmit } = useForm();

    const { nome, cognome, email, token } = user;

    const [userFirstname, setUserFirstname] = useState(nome);
    const [userLastname, setUserLastname] = useState(cognome);
    const [userEmail, setUserEmail] = useState(email);

    //Alert
    const [showAlert, setShowAlert] = useState(false);
    const [alertType, setAlertType] = useState('info');
    const [alertMessage, setAlertMessage] = useState('Prova');

    const handleFirstname = useCallback((e) =>
    {
        setUserFirstname(e.target.value);
    }, []);

    const handleLastname = useCallback((e) =>
    {
        setUserLastname(e.target.value);
    }, []);

    const handleEmail = useCallback((e) =>
    {
        setUserEmail(e.target.value);
    }, []);

    const notify = useCallback((type, message) => {
        setAlertType(type);
        setAlertMessage(message);
        setShowAlert(true);
    },[]);

    const handleEditAccount = async (data, event) => {
        event.preventDefault();

        setIsLoading(true);

        const formData = new FormData(event.target);

        const response = await fetch(
            `${apiRoot}/auth/account/`,
            {
                method: 'POST',
                headers: {
                    'Authorization': `Token ${token}`
                },
                body: formData
            }
        ).then(rsp => rsp.json())
            .catch(error => console.log(error.message));

        console.log('response', response);

        if ('error' === response.result)
        {
            setIsLoading(false);

            notify('danger', response.message);
            
            return false;
        }
        setUser({...user, nome: response.data.nome, cognome: response.data.cognome});

        setIsLoading(false);

        notify('success', response.result);

        return false;
    }

    const closeAlertCallback = () => {
        setShowAlert(false);
        setAlertType('info');
        setAlertMessage('');
    }

    return <>
        <header className="dashboard-header mb-5">
            <h2>{t('account.title')}</h2>
        </header>
        <div className="dashboard-body bg-white p-4 p-lg-5 flex-grow-1 row g-0">
            <div className="col-xl-8 col-xxl-6">
                <form id="edit-account-form" method="POST" onSubmit={handleSubmit(handleEditAccount)}>
                    <div className="dashbox">
                        <header className="dashbox-header">
                            <h4><i className="fa-light fa-address-card"></i> {t('account.personal_info.title')}</h4>
                        </header>
                        <div className="dashbox-body mt-4">
                            {/* <div className="intro">
                                <p className="mb-0 text-small text-mid">{t('account.personal_info.intro')}</p>
                            </div> */}
                            <div className="personal-information-fields row g-0 mt-4">
                                <div className="col-lg-6 pe-md-3">
                                    <div className="input-wrapper">
                                        <label className="text-xsmall text-uppercase">{t('account.personal_info.firstname.label')}</label>
                                        <div className="field position-relative">
                                            <input type="text" {...register("nome", { required: true, value: userFirstname })} onChange={handleFirstname} className="form-control" name="nome" placeholder={t('account.personal_info.firstname.placeholder')} />
                                            {errors.nome && <span className="validation-error">{t('account.personal_info.firstname.error')}</span>}
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6 mt-4 mt-lg-0 ps-md-3">
                                    <div className="input-wrapper">
                                        <label className="text-xsmall text-uppercase">{t('account.personal_info.lastname.label')}</label>
                                        <div className="field position-relative">
                                            <input onChange={handleLastname} type="text" {...register("cognome", { required: true, value: userLastname })} className="form-control" name="cognome" placeholder={t('account.personal_info.lastname.placeholder')} />
                                            {errors.cognome && <span className="validation-error">{t('account.personal_info.lastname.error')}</span>}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="dashbox mt-5">
                        <header className="dashbox-header">
                            <h4><i className="fa-light fa-at"></i> {t('account.email_address.title')}</h4>
                        </header>
                        <div className="dashbox-body mt-4">
                            {/* <div className="intro">
                                <p className="mb-0 text-small text-mid">{t('account.email_address.intro')}</p>
                            </div> */}
                            <div className="personal-information-fields row mt-4">
                                <div className="col-12">
                                    <div className="input-wrapper">
                                        <label className="text-xsmall text-uppercase">E-mail</label>
                                        <div className="field">
                                            <input readOnly={true} disabled={true} onChange={handleEmail} type="email" className="form-control" name="email" value={userEmail} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="cta mt-5 d-flex align-items-center mb-3">
                        <button type="submit" className="btn btn-primary btn-round px-3">{t('account.personal_info.save')}</button>{isLoading && (<div className="ms-2"><div className="small-spinner"></div></div>)}
                    </div>
                    <BsAlert show={showAlert} type={alertType} message={alertMessage} callback={closeAlertCallback}/>
                </form>
            </div>
        </div>
    </>
}

export default Account;