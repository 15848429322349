import { useCore } from "../Core";
import { apiRoot } from "../settings";
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useCallback, useEffect, useState } from "react";
import moment from "moment";

const Archive = () => {

    const {
        isLoading,
        setIsLoading,
        token
    } = useCore();

    const { t } = useTranslation();
    const navigate = useNavigate();

    const [confs, setConfs] = useState(null);

    useEffect(() => {
        let isActive = true;

        const fetchData = async () => {
            const headers = new Headers();
            headers.append("Authorization", `Token ${token}`);

            const userRequests = await fetch(
                `${apiRoot}/auth/account/`,
                {
                    method: 'GET',
                    headers: headers
                }
            ).then(rsp => rsp.json())
            .catch(error => console.log(error.message));

            if( 'error' === userRequests.result )
            {
                setIsLoading(false);
                alert(userRequests.message);
                return false;
            }
            if(isActive){
                setConfs(userRequests.configurazioni);
            }
        }
        fetchData();

        return () => {
            isActive = false;
        };
    },[]);

    const handleDeleteConfiguration = useCallback(async (target, id) => {
        const alert_message = t('archive.delete_alert_message').replace('%id%', id);
        if( window.confirm(alert_message) ){
            setIsLoading(true);
            
            target.addEventListener('transitionend', function(event){
                if( event.propertyName === 'opacity' )
                {
                    target.remove();
                    setIsLoading(false);
                }
            },false);

            const headers = new Headers();
            headers.append("Authorization", `Token ${token}`);

            const deleted = await fetch(
                `${apiRoot}/configuration/${id}/`,
                {
                    method: 'DELETE',
                    headers: headers
                }
            ).then(rsp => rsp.json())
            .catch(error => console.log(error.message));

            if( 'error' === deleted.result )
            {
                setIsLoading(false);
                alert(deleted.message);
                return false;
            }

            target.classList.add('deleting');
        }
    },[]);

    const archiveMarkup = () => {
        if( !confs )
        {
            return;
        }else if(confs && !confs.length){
            return <div className="alert alert-danger">{t('archive.no_results')}</div>
        }

        return (
            <table className="requests-archive table text-small">
                <thead>
                    <tr>
                        <td>Num.</td>
                        <td>{t('archive.table.data')}</td>
                        <td className="text-end">{t('archive.table.latitude')}</td>
                        <td className="text-end">{t('archive.table.longitude')}</td>
                        <td className="text-end">{t('archive.table.t_min')}</td>
                        <td className="text-end">{t('archive.table.t_max')}</td>
                        <td>&nbsp;</td>
                        <td>&nbsp;</td>
                    </tr>
                </thead>
                <tbody>
                    {confs.map((conf, index) => {
                        const dateString = conf.data_ora.split(' ');
                        return (
                            <tr key={index.toString()}>
                                <td className="align-middle">{conf.id}</td>
                                <td className="align-middle">{moment(dateString[0], 'YYYY-MM-DD').format('DD-MM-YYYY')}</td>
                                <td className="text-end align-middle">{conf.lat}</td>
                                <td className="text-end align-middle">{conf.lng}</td>
                                <td className="text-end align-middle">{conf.temp_min} °C</td>
                                <td className="text-end align-middle">{conf.temp_max} °C</td>
                                <td className="text-end align-middle">
                                    <button disabled={isLoading} type="button" className="btn btn-sm btn-primary" onClick={() => navigate(`/admin/archive/${conf.id}`)}>{t('archive.table.detail_btn_label')}</button>
                                </td>
                                <td className="text-end align-middle">
                                    <button type="button" onClick={(event) => handleDeleteConfiguration(event.target.closest('tr'), conf.id)} disabled={isLoading} className="reset-button"><i className="fa-solid fa-trash-can"></i></button>
                                </td>
                            </tr>
                        )
                    })}
                </tbody>
            </table>
        )
    }

    return <>
        <header className="dashboard-header mb-5">
            <h2>{t('archive.title')}</h2>
        </header>
        <div className="dashboard-body bg-white p-4 p-lg-5 flex-grow-1 row g-0">
            <div className="col-xl-12">
                <form id="archive-form" method="POST">
                    <div className="dashbox">
                        <header className="dashbox-header">
                            <h4><i className="fa-light fa-folder-open"></i> {t('archive.subtitle')}</h4>
                        </header>
                        <div className="dashbox-body mt-4">
                            {archiveMarkup()}
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </>
}

export default Archive;