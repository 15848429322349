import { useState, useEffect, useCallback } from "react";
import { Modal } from 'bootstrap';
import { useCore } from "../Core";
import { useNavigate, Navigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Header from '../components/Header';
import Footer from '../components/Footer';
import Toolbar from '../components/Toolbar';
import Intro from '../components/Intro';
import ModelTemplate from "../components/ModelTemplate";
import NewModelModal from "../components/NewModelModal";
import EditModelModal from "../components/EditModelModal";
import { apiRoot } from "../settings";

const Step2 = () =>
{
   const { t } = useTranslation();
   const navigate = useNavigate();

   const {
      idConfigurazione
   } = useParams();

   const {
      isLoading,
      setIsLoading,
      producer,
      setProducer,
      producers,
      setProducers,
      model,
      setModel,
      models,
      setModels,
      getData,
      getProducers,
      getModels,
      insertModule,
      setInverters,
      getInverters,
      user,
      setUser,
      moduleMinTemp,
      moduleMaxTemp,
      token
   } = useCore();

   useEffect(() =>
   {
      const modal_new_model = new Modal(document.getElementById('newModuleModal'), { backdrop: true });
      const modal_edit_model = new Modal(document.getElementById('editModuleModal'), { backdrop: true });
   }, []);

   const handleChangeProducer = useCallback(async (event) =>
   {
      const token = typeof user.token !== 'undefined' ? user.token : null;
      setIsLoading(true);
      const selectedProducer = event.target.value;
      setProducer(selectedProducer);
      setInverters([]);
      const modelsData = await (getModels(selectedProducer));
      if (modelsData && modelsData.length)
      {
         const first = modelsData.find(e => true);
         setModels(modelsData);
         setModel(first);
      }
      setIsLoading(false);
   }, [getModels, setIsLoading, setModel, setModels, setProducer]);

   const handleChangeModel = useCallback((event) =>
   {
      if (event.target.value !== -1)
      {
         const selectedModel = models.filter(el =>
         {
            return el.id === parseInt(event.target.value);
         }).shift();
         //console.log(selectedModel);
         setModel(selectedModel);
      } else
      {
         setModel({});
      }
   }, [models, setModel]);

   const openNewModuleModal = useCallback(() =>
   {
      const m = document.getElementById('newModuleModal');
      const mymodal = Modal.getInstance(m);
      mymodal.show();
   }, []);

   const closeNewModuleModal = useCallback(() =>
   {
      const m = document.getElementById('newModuleModal');
      const mymodal = Modal.getInstance(m);
      mymodal.hide();
   }, []);

   const openEditModuleModal = useCallback(() =>
   {
      if (!idConfigurazione)
      {
         const m = document.getElementById('editModuleModal');
         const mymodal = Modal.getInstance(m);
         mymodal.show();
      }

   }, []);

   const closeEditModuleModal = useCallback(() =>
   {
      const m = document.getElementById('editModuleModal');
      const mymodal = Modal.getInstance(m);
      mymodal.hide();
   }, []);

   const handleNewModelFormSubmit = useCallback(async (event) =>
   {
      event.preventDefault();
      const formData = new FormData(event.target);
      let formDataObject = {};
      formData.forEach((value, key) =>
      {
         if (key !== 'produttore' && key !== 'modello')
         {
            value = parseFloat(value);
         }
         formDataObject[key] = value;
      });
      const insert = await insertModule(formData);
      if (typeof insert.message !== 'undefined')
      {
         alert(insert.message);
      } else
      {
         setIsLoading(true);
         const producersData = await getProducers();
         const modelsData = await getModels(formDataObject.produttore);
         setModels(modelsData);
         setProducers(producersData);
         setProducer(formDataObject.produttore);
         setModel(insert);
         closeNewModuleModal();
         setIsLoading(false);
      }
      return false;
   }, [setProducer, setProducers, setModel, closeNewModuleModal, setIsLoading, setModels, getModels, getProducers, insertModule]);

   if (!moduleMinTemp || !moduleMaxTemp)
   {
      return <Navigate to="/" />;
   }

   const handleNextStep = () =>
   {
      const nextStepUrl = idConfigurazione ? `/edit/${idConfigurazione}/step-3` : '/step-3';
      navigate(nextStepUrl);
   }

   const handlePrevStep = () =>
   {
      const prevStepUrl = idConfigurazione ? `/edit/${idConfigurazione}` : '/';
      navigate(prevStepUrl)
   }

   const deleteModule = async () =>
   {
      if (!idConfigurazione)
      {
         setIsLoading(true);
         const headers = new Headers();
         headers.append("Content-Type", "application/json");
         headers.append("Authorization", `Token ${token}`);

         const check = await fetch(`${apiRoot}/modules/custom/${model?.id}/check/`, {
            method: 'GET',
            headers: headers,
         }).then(res => res.json());

         if (!check.checked)
         {
            if (!window.confirm(t('step2.model_details.edit_submit_button_confirm')))
            {
               setIsLoading(false);
               return
            }
         } else
         {
            if (!window.confirm(t('step2.model_details.delete_model_confirm')))
            {
               setIsLoading(false);
               return;
            }
         }

         const deleted = await fetch(`${apiRoot}/modules/custom/${model?.id}/`, {
            method: 'DELETE',
            headers: headers,
         }).then(res => res.json());

         const producersData = await getProducers();
         const firstProduttore = producersData.find(e => true);
         const modelsData = await getModels(firstProduttore.produttore);
         const firstModel = modelsData.find(e => true);
         setModels(modelsData);
         setProducers(producersData);
         setProducer(firstProduttore);
         setModel(firstModel);

         alert('Modulo eliminato correttamente');

         setIsLoading(false);
      }
   }

   return (
      <>
         <Header />
         <main className="app-main bg-white">
            <section className="wizard">
               <Toolbar title={t('toolbar.title')} subtitle={t('toolbar.subtitle')} activeStep={2} />
               <div className="step-content">
                  <div className="container-fluid px-0 step step-2">
                     <div className="row g-0 align-items-start">
                        <div className="layout-column first col-md-4 d-flex flex-column bg-white sticky-top">
                           <Intro icon="fa-light fa-solar-panel" title={t('step2.intro.title')} subtitle={t('step2.intro.subtitle')}>
                              <div className="info-action">
                                 <p><button onClick={() => handleNextStep()} className="btn btn-primary btn-next btn-round px-3">{t('general.continue_btn')}</button></p>
                                 <p><button onClick={() => handlePrevStep()} className="reset-button text-decoration-underline text-dark text-small">{t('general.back')}</button></p>
                              </div>
                           </Intro>
                        </div>
                        <div className="layout-column last col-md-8 p-4 p-md-5 bg-lighest">
                           <div className="wrapper">
                              <div className="full-container">
                                 <div className="row g-0 pb-5 justify-content-md-start">
                                    <div className="col-lg-6 col-xl-4">
                                       <div className="input-group">
                                          <select id="producers-selector" value={producer} disabled={isLoading} className="producers-selector form-select btn-round" onChange={handleChangeProducer}>
                                             {producers && producers.map((el, index) =>
                                             {
                                                return <option key={index.toString()} value={el.produttore}>{el.produttore}</option>
                                             })}
                                          </select>
                                          <select id="models-selector" value={model ? model.id : '-1'} disabled={isLoading} className="models-selector form-select btn-round" onChange={handleChangeModel}>
                                             {models && models.map((el, index) =>
                                             {
                                                return <option key={el.modello} value={el.id}>{el.modello}</option>
                                             })}
                                          </select>
                                       </div>
                                    </div>
                                    {
                                       (model?.custom === true && !idConfigurazione) && (
                                             <div className="col-lg-6 col-xl-auto ps-lg-2 mt-3 mt-lg-0">
                                                <div className="input-group">
                                                   <button
                                                      type="button"
                                                      className="btn btn-primary d-flex align-items-center justify-content-center flex-grow-1 btn-round px-3"
                                                      onClick={openEditModuleModal}
                                                   >{t('step2.model_edit')}</button>
                                                   <span>&nbsp;</span>
                                                   <button
                                                      type="button"
                                                      className="btn btn-primary d-flex align-items-center justify-content-center flex-grow-1 btn-round px-3"
                                                      onClick={() => deleteModule()}
                                                   >{t('step2.model_delete')}</button>
                                                </div>
                                             </div>
                                       )
                                    }

                                    {token && (
                                       <div className="col-12 col-xl-auto ms-auto d-flex flex-column mt-3 mt-xl-0">
                                          <button
                                             type="button"
                                             onClick={openNewModuleModal}
                                             className="btn btn-primary d-flex align-items-center justify-content-center flex-grow-1 btn-round px-3"
                                          >{t('step2.custom_module_button')}&nbsp;&nbsp;<i className="fa-solid fa-plus"></i></button>
                                       </div>
                                    )}

                                 </div>
                                 <div className="boxed w-100 p-3 p-md-5">
                                    {model && <ModelTemplate />}
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </section>
         </main>
         <Footer />
         <NewModelModal onSubmit={handleNewModelFormSubmit} />
         <EditModelModal model={model} onClose={closeEditModuleModal} />
      </>
   )
}
export default Step2;