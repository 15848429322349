import React, { useContext, createContext, useState, useEffect, useCallback } from 'react';
import { useLocalStorage } from './useLocalStorage';
import { apiRoot } from "./settings";
import { useNavigate, useSearchParams } from 'react-router-dom';

const coreContext = createContext();

export function useCore()
{
    return useContext(coreContext);
}

export function ProvideCore({ children })
{
    const [isLoading, setIsLoading] = useState(true);
    const [latitude, setLatitude] = useLocalStorage('_wco_latitude', 41.9100498);
    const [longitude, setLongitude] = useLocalStorage('_wco_longitude', 12.4659588);
    const [minTemp, setMinTemp] = useLocalStorage('_wco_mintemp', '');
    const [maxTemp, setMaxTemp] = useLocalStorage('_wco_maxtemp', '');
    const [moduleMinTemp, setModuleMinTemp] = useLocalStorage('_wco_modulemintemp', '');
    const [moduleMaxTemp, setModuleMaxTemp] = useLocalStorage('_wco_modulemaxtemp', '');
    const [appData, setAppData] = useState({});
    const [producer, setProducer] = useLocalStorage('_wco_producer', '');
    const [producers, setProducers] = useLocalStorage('_wco_producers', []);
    const [model, setModel] = useLocalStorage('_wco_model', {});
    const [models, setModels] = useLocalStorage('_wco_models', []);
    const [invertersData, setInvertersData] = useLocalStorage('_wco_invertersdata', []);
    const [inverters, setInverters] = useLocalStorage('_wco_inverters', []);
    const [inverterMode, setInverterMode] = useLocalStorage('_wco_invertermode', 'M');
    const [result, setResult] = useLocalStorage('_wco_result', null);
    const [user, setUser] = useLocalStorage('_wco_user', {});
    const [selectedReport, setSelectedReport] = useLocalStorage('_wco_selectedreport', {});
    const [token, setToken] = useLocalStorage('_wco_token', null);
    const [language, setLanguage] = useLocalStorage('_wco_lang', 'it');

    const [searchParams, setSearchParams] = useSearchParams();

    const navigate = useNavigate();

    useEffect(() => {
        const allowedLangs = ['it','en'];
        const lang = searchParams.get("lang");
        console.log(process.env);
        if(lang && allowedLangs.includes(lang))
        {
            setLanguage(lang);
        }
    },[]);

    /**
     * Pulisco tutto lo storage
     */

    const clearAll = () => {
        setLatitude(41.9100498);
        setLongitude(12.4659588);
        setMinTemp('');
        setMaxTemp('');
        setModuleMinTemp('');
        setModuleMaxTemp('');
        setInverters([]);
        setInverterMode('M');
        setSelectedReport({});
        navigate('/');
    }

    /**
     * gestione del logout
     */
    const handleLogout = () => {
        if( window.confirm('Sei sicuro di voler effettuare il logout?') ){
            clearAll();
            setUser({});
            setToken(null);
            setSelectedReport({});
            navigate('/');
        }
    }

    /**
     * Ottiene la lista dei produttori
     * se il parametro "token" è popolato, restituisce anche i produttori custom
     */
    const getProducers = useCallback(async () =>
    {
        const headers = new Headers();
        headers.append("Content-Type", "application/json");
        let endpoint = `${apiRoot}/modules/producers/`;
        if( token )
        {
            endpoint += 'custom/';
            headers.append("Authorization", `Token ${token}`);
        }
        const producers = await fetch(
            endpoint,
            {
                method: 'GET',
                headers: headers,
            }
        )
        .then( response => response.json() )
        .catch( error => {
            alert(error.message);
        });

        return producers;
    }, [token]);

    /**
     * restituisce i modelli per uno specifico produttore
     * se il parametro "token" è popolato, restituisce anche i modelli custom
     */
    const getModels = useCallback(async (producer) =>
    {
        const headers = new Headers();
        headers.append("Content-Type", "application/json");
        let endpoint = `${apiRoot}/modules/`;
        if( token )
        {
            endpoint += 'custom/';
            headers.append("Authorization", `Token ${token}`);
        }
        endpoint += `?prod=${producer}`;

        const models = await fetch(
            endpoint,
            {
                method: 'GET',
                headers: headers
            }
        )
        .then( response => response.json() )
        .catch( error => {
            alert(error.message);
        });

        return models;
    }, [token]);

    /**
     * inserisce un modulo custom
     */
    const insertModule = useCallback(async (formData) =>
    {
        const headers = new Headers();
        if( token )
        {
            headers.append("Authorization", `Token ${token}`);
        }
        const insert = await fetch(
            `${apiRoot}/modules/custom/`,
            {
                method: 'POST',
                headers: headers,
                body: formData,
            }
        ).then(response => response.json()).catch(error => console.log(error));
        return insert;
    }, [token]);

    /**
     * restituisce elenco degli inverters
     */
    const getInverters = useCallback(async () =>
    {
        const headers = new Headers();
        headers.append("Content-Type", "application/json");
        let endpoint = `${apiRoot}/inverters/`;

        const inverters = await fetch(
            endpoint,
            {
                method: 'GET',
                headers: headers
            }
        )
        .then( response => response.json() )
        .catch( error => {
            alert(error.message);
        });

        return inverters;
    }, []);

    useEffect(() =>
    {
        const checkForProducer = (producer) => {
            return producers.some(function(el) {
                return el.produttore === producer;
            }); 
        }

        const checkForModel = (model_id) => {
            return models.some(function(el) {
                return el.id === model_id;
            }); 
        }
        
        const initializeData = async function()
        {
            try
            {
                setIsLoading(true);
                const p = await getProducers();
                const i = await getInverters();
                if(!p.length || !i.length)
                {
                    throw new Error(`Alcuni dati essenziali non sono disponibili`);
                }
                const fp = p.find(e => true);
                
                setProducers(p);

                if( !producer || !checkForProducer(producer) )
                {
                    setProducer(fp.produttore);
                }

                const m = await getModels(producer);

                const fm = m.find(e => true);

                setModels(m);
                if( typeof model.id === 'undefined' || !checkForModel(model.id) )
                {
                    setModel(fm);
                }
                
                setInvertersData(i);
                setIsLoading(false);
            } catch (err)
            {
                console.log(err);
            }
        }
        initializeData(token);

    }, [token]);

    return (
        <coreContext.Provider value={
            {
                isLoading,
                setIsLoading,
                language,
                setLanguage,
                latitude,
                setLatitude,
                longitude,
                setLongitude,
                minTemp,
                setMinTemp,
                maxTemp,
                setMaxTemp,
                moduleMinTemp,
                setModuleMinTemp,
                moduleMaxTemp,
                setModuleMaxTemp,
                model,
                setModel,
                invertersData,
                setInvertersData,
                inverterMode,
                setInverterMode,
                inverters,
                setInverters,
                result,
                setResult,
                appData,
                setAppData,
                producer,
                setProducer,
                producers,
                setProducers,
                models,
                setModels,
                user,
                setUser,
                selectedReport,
                setSelectedReport,
                token,
                setToken,
                handleLogout,
                getProducers,
                getModels,
                insertModule,
                getInverters,
                clearAll,
            }
        }>
            {children}
        </coreContext.Provider>
    );
}
