import React from 'react';
import ReactDOM from 'react-dom/client';
import
{
    BrowserRouter,
} from "react-router-dom";
import './scss/style.scss';
import App from './App';
//import reportWebVitals from './reportWebVitals';
import { ProvideCore } from "./Core";
import i18n from './i18n';
import { I18nextProvider } from 'react-i18next';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <>
        <I18nextProvider i18n={i18n}>
            <BrowserRouter basename="/app/">
                <ProvideCore>
                    <App />
                </ProvideCore>
            </BrowserRouter>
        </I18nextProvider>
    </>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();
