import logo from '../images/logo-westernco.png';
import { useNavigate, NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useCore } from "../Core";

const Header = () => {

    const {
        user,
        token,
        handleLogout
    } = useCore();

    const navigate = useNavigate();
    const {t} = useTranslation();

    const welcomeMessage = t('header.welcome_message');

    return (
        <header className={"app-header d-flex flex-column flex-md-row justify-content-between align-items-center position-relative"}>
            <div className='left-side col ps-md-5 position-relative z-index-2 d-flex'>
                <div className='user-badge lh-1 py-3 px-3 text-white'><span className={`btn btn-round btn-sm text-white ${token ? ` bg-primary`:`bg-grey`}`}><i className="fa-solid fa-user"></i></span>&nbsp;&nbsp;
                {token ? (<>{welcomeMessage.replace('%user%', user.nome)} <span className="px-2">|</span><button className="reset-button text-white" onClick={() => navigate('/admin')}>Admin</button><span className="px-2">|</span><button className="reset-button text-white" onClick={handleLogout}>Logout</button></>):(<button onClick={() => navigate('/admin/account/login')} className="reset-button text-white"><u>{t('header.login_label')}</u></button>)}
                </div>
            </div>
            <div className="logo col-auto">
                <NavLink to='/' className="d-bock">
                    <img src={logo} alt={"Western CO"} className={"img-fluid"} loading={"lazy"}/>
                </NavLink>
            </div>
            <div className='right-side col pe-5'></div>
        </header>
    )
}
export default Header;