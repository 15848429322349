import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import it from "./locales/it";
import en from "./locales/en";

const userLanguage = window.navigator.userLanguage || window.navigator.language;
i18n
    .use(initReactI18next)
    .init({
        resources: {
            en: en,
            it: it
        },
        lng: userLanguage.substring(0, 2),
        fallbackLng: "it",
        pluralSeparator: "__",
        // pluralization options
  plural: (n) => {
   if (n === 1) {
     return "one";
   }
   return "other";
 },
        interpolation: {
            escapeValue: false
        }
    });


export default i18n;