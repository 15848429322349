const it = {
    translation: {
        "general": {
            "back": "Indietro",
            "continue_btn": "Continua"
        },
        "header": {
            "login_label": "Effettua il login",
            "welcome_message": "Benvenuto %user%"
        },
        "footer": {
            "italy": "Italia",
            "rights": "Tutti i diritti riservati",
            "vat": "Partita Iva"
        },
        "toolbar": {
            "title": "Dimensionatore",
            "subtitle": "Inverter di stringa"
        },
        "step1": {
            "intro": {
                "title": "Scegli la località nella quale installare l'impianto e imposta la temperatura",
                "subtitle": "Sposta il marker sulla mappa oppure inserisci e modifica le coordinate manualmente."
            },
            "map": {
                "city_label": "Città",
                "coords_label": "Posizione",
                "autocomplete_placeholder": "Inserisci la tua città",
                "latitude": "Latitudine",
                "longitude": "Longitudine",
                "temp_label": "Inserire il range di temperatura di funzionamento",
                "min_temp": "Temp. min.",
                "max_temp": "Temp. max.",
                "modulo_max_temp": "Temp. max. modulo",
                "modulo_min_temp": "Temp. min. modulo",
            }
        },
        "step2": {
            "intro": {
                "title": "Seleziona il tuo modulo",
                "subtitle": "Scegli il tuo modulo selezionando produttore e modello, oppure imposta un modulo personalizzato"
            },
            "custom_module_button": "modulo personalizzato",
            "model_edit": "Modifica",
            "model_delete": "Cancella",
            "model_details": {
                "producer": "Produttore",
                "model": "Modello",
                "length": "Lunghezza",
                "width": "Larghezza",
                "submit_button": "Conferma inserimento",
                "edit_submit_button": "Applica modifiche",
                "new_module_title": "Inserisci nuovo modello",
                "edit_module_title": "Modifica modello",
                "edit_submit_button_confirm": "Tutte le configurazioni archiviate che contengono questo modulo verranno cancellate. Sei sicuro di voler procedere?",
                "delete_model_confirm": "Sei sicuro di voler cancellare questo modulo?\nL\x27operazione non potrà essere annullata"
            }
        },
        "step3": {
            "intro": {
                "title": "Seleziona una o più tipologie di inverter",
                "subtitle": "Clicca sul prodotto per avere maggiori informazioni e spunta la casella per selezionarlo"
            },
            "types": {
                "monofase": "monofase",
                "trifase": "trifase",
                "ibrido": "ibrido"
            },
            "card": {
                "details_button_label":"Dettagli"
            },
            "inverter_details": {
               "model": "Modello",
               "code": "Codice",
               "mppt_inputs": "N. Ingressi per MPPT",
               "ac_output_voltage": "Tensione USCITA AC [V]",
               "ac_output_frequency": "Frequenza USCITA AC [Hz]",
            },
            "mppt_header": "{{ count }} Stringa MPPT",
            "mppt_header_plural": "{{ count }} Stringhe MPPT"
        },
        "step4": {
            "intro": {
                "title": "Risultati della configurazione",
                "subtitle": "Lorem ipsum dolor sit amet consectetur adispiscing elit",
                "cta_btn": "Salva configurazione",
                "update_btn": "Applica modifiche"
            },
            "data_entered": "Dati inseriti",
            "location": "Località",
            "temperature": "Temperatura",
            "selected_model": "Modello selezionato",
            "selected_inverters": "Inverters selezionati",
            "results":{
                "title": "La tua configurazione",
                "no_results_message": "Spiacenti ma non è stato possibile trovare una configurazione valida con l'inverter <strong>%inverter%</strong>.<br/><a href=\"%url%\">contattare la Western CO</a> per ulteriori informazioni",
                "table": {
                    "heading": "Pannelli in serie",
                    "sub_heading": "Stringhe in parallelo",
                    "submit_btn": "Stampa report di configurazione"
                }
            }
        },
        "register": {
            "welcome": "<strong>Benvenuto!</strong><br/>Per effettuare la registrazione, inserisci i tuoi dati.",
            "already_registered": "Già registrato?",
            "form": {
                "title": "Registrati",
                "labels": {
                    "firstname": "Nome",
                    "lastname": "Cognome",
                    "email": "E-mail",
                    "password": "Password",
                    "confirm": "Conferma password"
                },
                "placeholders": {
                    "firstname": "Inserisci il tuo nome",
                    "lastname": "Inserisci il tuo cognome",
                    "email": "Inserisci la tua e-mail",
                    "password": "Inserisci la tua password",
                    "confirm": "Conferma password"
                },
                "submit_label": "Registrati",
            }
        },
        "account": {
            "title": "Il mio Account",
            "personal_info": {
                "title": "Informazioni personali",
                "intro": "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
                "firstname": {
                    "label": "Nome",
                    "placeholder": "Inserisci il tuo nome",
                    "error": "Inserisci il tuo nome"
                },
                "lastname": {
                    "label": "Cognome",
                    "placeholder": "Inserisci il tuo cognome",
                    "error": "Inserisci il tuo cognome"
                },
                "save": "Registra le modifiche"
            },
            "email_address": {
                "title": "Indirizzo e-mail",
                "intro": "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."
            }
        },
        "dashboard_nav": {
            "account_label" : "il Mio account",
            "archive_label" : "Archivio richieste",
            "new_request_labek" : "Nuova richiesta"
        },
        "archive": {
            "title" : "Archivio richieste",
            "subtitle": "le mie configurazioni",
            "table": {
                "data": "Data",
                "latitude": "Latitudine",
                "longitude": "Longitudine",
                "t_min": "Temp. min.",
                "t_max": "Temp. max.",
                "detail_btn_label": "mostra dettaglio"
            },
            "delete_alert_message": "Sei sicuro di voler eliminare la configurazione #%id%? Questa operazione non può essere annullata",
            "no_results": "Nessuna configurazione salvata"
        },
        "configuration_item": {
            "title": "Dettaglio configurazione",
            "subtitle": "Configurazione #%id% del %date%"
        },
        "login": {
            "alert_after_recover": "Controlla la tua e-mail",
            "welcome_message": "<strong>Bentornato!</strong><br/>Per iniziare ad utilizzare l'applicazione, esegui il login.",
            "no_account_message": "Non hai un account?",
            "form": {
                "placeholders": {
                    "email": "Inserisci la tua email",
                    "password": "Inserisci la tua password"
                },
                "errors": {
                    "email": "Inserisci un indirizzo email valido",
                    "password": "Inserisci una password di aleno 8 caratteri"
                },
                "submit_label": "Accedi",
                "forgot_password": "Password dimenticata?"
            }
        },
        "recover": {
            "welcome_message": "Inserisci la tua e-mail per poter recuperare la tua password",
            "back_button": "Torna al login",
            "form": {
                "placeholders": {
                    "email": "Inserisci la tua e-mail",
                },
                "errors": {
                    "email": "Inserisci un indirizzo email valido"
                },
                "submit_label": "Recupera",
            }
        }
    }
}
export default it