import { useCore } from "../Core";
import { useTranslation } from 'react-i18next';
const ModelTemplate = () => {
    const {
        model
    } = useCore();

    const { t } = useTranslation();

    return (
        model && <div className="module-sheet">
            <div className="body px-lg-4">
                <h4>{model.produttore} {model.modello}</h4>
                <div className="product-info mt-4">
                    <table className="table table-bordered">
                        <thead>
                            <tr>
                                <th>&nbsp;</th>
                                <th>{model.modello}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td className="bg-primary text-white">
                                    <p className="mb-0">{t('step2.model_details.producer')}</p>
                                </td>
                                <td className="bg-light-grey text-end">
                                    <p className="mb-0">{model.produttore}</p>
                                </td>
                            </tr>
                            <tr>
                                <td className="bg-primary text-white">
                                    <p className="mb-0">{t('step2.model_details.model')}</p>
                                </td>
                                <td className="bg-light-grey text-end">
                                    <p className="mb-0">{model.modello}</p>
                                </td>
                            </tr>
                            <tr>
                                <td className="bg-primary text-white">
                                    <p className="mb-0">Pnom[Wp]</p>
                                </td>
                                <td className="bg-light-grey text-end">
                                    <p className="mb-0">{model.p_nom}</p>
                                </td>
                            </tr>
                            <tr>
                                <td className="bg-primary text-white">
                                    <p className="mb-0">Vmp[V]</p>
                                </td>
                                <td className="bg-light-grey text-end">
                                    <p className="mb-0">{model.v_mp}</p>
                                </td>
                            </tr>
                            <tr>
                                <td className="bg-primary text-white">
                                    <p className="mb-0">Imp[A]</p>
                                </td>
                                <td className="bg-light-grey text-end">
                                    <p className="mb-0">{model.i_mp}</p>
                                </td>
                            </tr>
                            <tr>
                                <td className="bg-primary text-white">
                                    <p className="mb-0">Voc[V]</p>
                                </td>
                                <td className="bg-light-grey text-end">
                                    <p className="mb-0">{model.v_oc}</p>
                                </td>
                            </tr>
                            <tr>
                                <td className="bg-primary text-white">
                                    <p className="mb-0">Isc[A]</p>
                                </td>
                                <td className="bg-light-grey text-end">
                                    <p className="mb-0">{model.i_sc}</p>
                                </td>
                            </tr>
                            <tr>
                                <td className="bg-primary text-white">
                                    <p className="mb-0">MaxSysVoltage[V]</p>
                                </td>
                                <td className="bg-light-grey text-end">
                                    <p className="mb-0">{model.max_sys_v}</p>
                                </td>
                            </tr>
                            <tr>
                                <td className="bg-primary text-white">
                                    <p className="mb-0">VOC Temp Coef[%/C]</p>
                                </td>
                                <td className="bg-light-grey text-end">
                                    <p className="mb-0">{model.voc_temp_coef}</p>
                                </td>
                            </tr>
                            <tr>
                                <td className="bg-primary text-white">
                                    <p className="mb-0">Max system voltage</p>
                                </td>
                                <td className="bg-light-grey text-end">
                                    <p className="mb-0">{model.max_sys_v}</p>
                                </td>
                            </tr>
                            <tr>
                                <td className="bg-primary text-white">
                                    <p className="mb-0">ISC Temp Pmax [%/C]</p>
                                </td>
                                <td className="bg-light-grey text-end">
                                    <p className="mb-0">{model.isc_temp_pmax}</p>
                                </td>
                            </tr>
                            <tr>
                                <td className="bg-primary text-white">
                                    <p className="mb-0">Pmax Temp Coef[%/C]</p>
                                </td>
                                <td className="bg-light-grey text-end">
                                    <p className="mb-0">{model.pmax_temp_coef}</p>
                                </td>
                            </tr>
                            <tr>
                                <td className="bg-primary text-white">
                                    <p className="mb-0">{t('step2.model_details.length')} [mm]</p>
                                </td>
                                <td className="bg-light-grey text-end">
                                    <p className="mb-0">{model.lunghezza}</p>
                                </td>
                            </tr>
                            <tr>
                                <td className="bg-primary text-white">
                                    <p className="mb-0">{t('step2.model_details.width')} [mm]</p>
                                </td>
                                <td className="bg-light-grey text-end">
                                    <p className="mb-0">{model.larghezza}</p>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    )
}
export default ModelTemplate;