import DashboardNav from "../components/DashboardNav";
import DashboardMobileNav from "../components/DashboardMobileNav";
import { useCore } from "../Core";
import
{
    Navigate,
    Outlet

} from "react-router-dom";

const Admin = () =>
{
    const {
        token
    } = useCore();

    if(!token)
    {
        return <Navigate to="/admin/account/login" />;
    }

    return (
        <>
            <div className="row flex-column flex-lg-row align-items-start g-0 dashboard position-relative">
                <DashboardNav/>
                <div className="dashboard-contentcol-lg col pb-5 pb-lg-0 ps-4 pe-4 ps-lg-5 pe-lg-0 pt-5 d-flex flex-column align-self-stretch overflow-hidden">
                    <Outlet/>
                </div>
            </div>
            <DashboardMobileNav/>
            <div className="mobile-nav-push d-lg-none"></div>
        </>
    )
}
export default Admin;