import { useCallback, useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import { useCore } from "../Core";

const InverterCard = (props) =>
{
    const {
        id,
        modello,
        tensione_uscita,
        image_url,
        getDetails
    } = props;

    const {
        inverters,
        setInverters
    } = useCore();

    const { t } = useTranslation();

    const [selected, setSelected] = useState(false);

    useEffect(() => {
        const objectIsInArray = inverters.filter(e => e.id === id).length > 0;
        console.log('inverters.includes(id)', objectIsInArray);
        setSelected(objectIsInArray);
    },[inverters,id]);

    const removeFromArray = (arr, value) => {
        return arr.filter(function(ele){ 
            return ele.id !== value; 
        });
    }
    
    const handleChange = useCallback((event) => {
        const checked = event.target.checked;
        console.log('props.id',props.id);
        if( checked )
        {
            setInverters([...inverters, props]);
            console.log('inverters after add', inverters);
        }else{
            const remains = removeFromArray(inverters, props.id);
            setInverters(remains);
            console.log('inverters after remove', inverters);
        }
        setSelected(checked);
    },[props, inverters, setInverters]);

    const triggerChange = useCallback((event) => {
        const card = event.target.closest('.box-card');
        const checkbox = card.querySelector('input[name="inverters[]"]');
        checkbox.click();
    },[]);

    return (
        <div className={`box-card mb-0${selected ? ` selected`:``}`}>
            <div className="ui-wrapper">
                <figure onClick={triggerChange} className="mb-0 bg-white">
                    <div className="figure-ui-wrapper d-flex flex-column align-items-center justify-content-center">
                        {
                        image_url ? (
                            <img src={image_url} className="img-fluid"/>
                        ):
                        (
                            <i className="fa-solid fa-transformer-bolt"></i>
                        )
                        }
                    </div>
                </figure>
                <div className="card-body bg-lightest text-center">
                    <div className="custom-control-label">{modello} <small className="text-xsmall">Ac Output:<br/> {tensione_uscita}</small></div>
                    <input checked={selected} onChange={handleChange} className="d-none" type="checkbox" name="inverters[]" value={id} id={`inverted-${id}`} />
                    <label className="ckecker pt-2" htmlFor={`inverted-${id}`}>
                        <i className="fa-solid fa-square text-grey"></i>
                        <i className="fa-solid fa-square-check text-primary"></i>
                    </label>
                    <div className="cta text-center mt-2">
                        <button type="button" onClick={() => getDetails(props)} className="btn btn-sm btn-primary btn-round">{t('step3.card.details_button_label')}</button>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default InverterCard;