import { apiRoot } from "../settings";
import { useCore } from "../Core";
import { useNavigate, useParams } from 'react-router-dom';
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import ResultTable from "../components/ResultTable";
import moment from "moment";

const ConfigurationItem = () => {

    const {
        isLoading,
        setIsLoading,
        user,
        setUser,
        selectedReport,
        setSelectedReport,
        setLatitude,
        setLongitude,
        setMinTemp,
        setMaxTemp,
        setModuleMinTemp,
        setModuleMaxTemp,
        setModel,
        setModels,
        setProducers,
        getProducers,
        getModels,
        setProducer,
        setInverters,
        token
    } = useCore();

    let { id } = useParams();
    const { t } = useTranslation();
    const navigate = useNavigate();

    const [activeButton, setActiveButton] = useState(null);
    const [config, setConfig] = useState({});
    const [updating, setUpdating] = useState(false);

    useEffect(() => {

        let isActive = true;

        const fetchConfiguration = async () => {
            const headers = new Headers();
            headers.append("Authorization", `Token ${token}`);

            const configData = await fetch(
                `${apiRoot}/configuration/${id}/`,
                {
                    method: 'GET',
                    headers: headers
                }
            ).then(rsp => rsp.json())
            .catch(error => console.log(error.message));

            if( 'error' === configData.result )
            {
                setIsLoading(false);
                alert(configData.message);
                return false;
            }
            if(isActive){
                setConfig(configData);

            }
        }
        fetchConfiguration();

        return () => {
            isActive = false;
        };
    },[id]);

    const handleSelectedReport = useCallback((event, inverter) => {
        const inverterId = inverter.id;
        const formElement = event.target.form;
        const selections = formElement.querySelectorAll('input[type=radio]:checked');
        console.log('selections.length', selections.length);
        console.log('inverter.stringhe', inverter.stringhe);
        let tot = 0;
        if( selections.length )
        {
            [].slice.call(selections).forEach((selection, indice) => {
                const pannelli = Number(selection.dataset.serie);
                const stringhe = Number(selection.dataset.strigs);
                console.log('pannelli', pannelli);
                console.log('stringhe', stringhe);
                tot += (pannelli * stringhe);
            });
        }
        console.log('selections.length', selections.length);
        console.log('inverter.stringhe', inverter.stringhe);
        formElement.querySelector('.table-output-block-total-modules > span').innerHTML = tot;
        if(selections.length === inverter.stringhe)
        {
            setActiveButton(inverterId);
            const yOffset = -100;
            const element = document.getElementById(`table-output-block-cta-${inverterId}`);
            if(element){
                const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
                window.scrollTo({top: y, behavior: 'smooth'});
            }
        }
    },[]);

    if( !Object.keys(config).length ){
        return;
    }

    const confData = JSON.parse(config.conf);
    const {model, inverters, moduleMinTemp, moduleMaxTemp} = confData;

    const buildReport = (e) => {
        e.preventDefault();
        const formData = new FormData(e.target);
        const selections = e.target.querySelectorAll('input[type=radio]:checked');
        const data = [];
        if( selections.length )
        {
            [].slice.call(selections).forEach((el, i) => {
                const props = {};
                for( let k in el.dataset )
                {
                    props[k] = Number(el.dataset[k]);
                }
                data.push(props);
            });
        }
        const reportParams = {
            inverter: JSON.parse(formData.get('inverter')),
            model: JSON.parse(formData.get('model')),
            data: data,
            latitude: config.lat,
            longitude: config.lng,
            minTemp: config.temp_min,
            maxTemp: config.temp_max,
            moduleMinTemp: moduleMinTemp,
            moduleMaxTemp: moduleMaxTemp
        }
        console.log('reportParams', reportParams);
        setSelectedReport(reportParams);
        window.open('/app/print-report');
        return false;
    }

    const handleEditConfiguration = async () => {
      setUpdating(true);
      console.log('config', config);
      const data = JSON.parse(config.conf);
      console.log('DATA', data);
      const producersData = await getProducers();
      const firstProduttore = producersData.find(e => true);
      const modelsData = await getModels(model?.produttore);

      setLatitude(config?.lat);
      setLongitude(config?.lng);
      setMinTemp(config?.temp_min);
      setMaxTemp(config?.temp_max);
      setModuleMinTemp(moduleMinTemp);
      setModuleMaxTemp(moduleMaxTemp);
      setProducers(producersData);
      setProducer(model?.produttore);
      setModels(modelsData);
      setModel(model);
      setInverters(inverters);

      navigate(`/edit/${id}`);
    }
    
    const dateString = config.data_ora.split(' ');

    const subtitle = t('configuration_item.subtitle').replace('%id%', id).replace('%date%', moment(dateString[0], 'YYYY-MM-DD').format('DD-MM-YYYY'));

    return <>
        <header className="dashboard-header mb-5">
            <h2 className="mb-0">{t('configuration_item.title')}</h2>
        </header>
        <div className="dashboard-body bg-white p-4 p-lg-5 flex-grow-1 row g-0">
            <div className="col-xl-8">
                <div id="archive-form">
                    <div className="dashbox">
                        <header className="dashbox-header d-flex align-items-center">
                            <h4 className="mb-0"><i className="fa-light fa-file-check"></i> {subtitle}</h4>
                            <button disabled={updating} onClick={handleEditConfiguration} type="button" className="btn btn-primary btn-round ms-3">Modifica</button>
                        </header>
                        <div className="dashbox-body mt-4">
                            {/* data entered */}
                            <div className="data-box-body mt-5">
                                <ul className="list-group p-0">
                                    <li className="list-group-item">
                                        <div className="row align-items-center">
                                            <div className="col-md-5">
                                                <label>
                                                    <i className="fa-light fa-map-location-dot"></i> {t('step4.location')}
                                                </label>
                                            </div>
                                            <div className="col-md-7">
                                                <div className="data">
                                                    <span className="result_lan">{config.lat}</span>, <span className="result_lng">{config.lng}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                    <li className="list-group-item">
                                        <div className="row align-items-center">
                                            <div className="col-md-5">
                                                <label>
                                                <i className="fa-solid fa-temperature-half"></i> {t('step4.temperature')}
                                                </label>
                                            </div>
                                            <div className="col-md-7">
                                                <div className="data">
                                                    Min. {config.temp_min}°, Max. {config.temp_max}°
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                    <li className="list-group-item">
                                        <div className="row align-items-center">
                                            <div className="col-md-5">
                                                <label>
                                                <i className="fa-light fa-solar-panel"></i> {t('step4.selected_model')}
                                                </label>
                                            </div>
                                            <div className="col-md-7">
                                                <div className="data">
                                                {model.produttore} {model.modello}
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                    <li className="list-group-item">
                                        <div className="row align-items-center">
                                            <div className="col-md-5">
                                                <label>
                                                <i className="fa-solid fa-transformer-bolt"></i> {t('step4.selected_inverters')}
                                                </label>
                                            </div>
                                            <div className="col-md-7">
                                                <div className="data">
                                                {inverters.map(el => el.modello).join(', ')}
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                             {/* data entered */}
                            <div className="data-box-body mt-5">
                                <div className="configuration-result mt-5">
                                    {
                                        inverters.map((el, key) => {
                                            return <ResultTable key={key.toString()} model={model} inverter={el} moduleMinTemp={moduleMinTemp} moduleMaxTemp={moduleMaxTemp} activeButton={activeButton} handleSelectedReport={handleSelectedReport} buildReport={buildReport} />
                                        })
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
}
export default ConfigurationItem;