import Header from '../components/Header';
import Footer from '../components/Footer';
import Toolbar from '../components/Toolbar';
import Intro from '../components/Intro';
import { useCallback, useEffect, useState, useRef, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useCore } from "../Core";
import { useNavigate, useParams } from 'react-router-dom';
import L from 'leaflet';
import { MapContainer, TileLayer, Marker, Popup, useMap } from 'react-leaflet';
import useAutoComplete from '../components/use-autocomplete';
import 'leaflet/dist/leaflet.css';

const containerStyle = {
   width: '100%',
   height: '100%',
   position: 'relative',
   display: 'flex',
   flexDirection: 'column',
   justifyContent: 'end',
   alignItems: 'stretch'
};

const libraries = ["places"];

const Step1 = () =>
{
   //const googleMapsApiKey = "AIzaSyCRD1w5Vthl6FcSCb_sZbvwBIyJFAMd3oY";
   //const googleMapsApiKey = "AIzaSyBtUVSIOIEurpnyVOLRm8z0ZRE4nmbVfPc";

   const { t } = useTranslation();

   const navigate = useNavigate();

   const {
      idConfigurazione
   } = useParams();

   const [autocompleteObject, setAutocompleteObject] = useState(null);

   const mapMarker = L.icon({
      iconUrl: "data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 384 512'%3E%3Cpath fill='%23dc332c' d='M215.7 499.2C267 435 384 279.4 384 192C384 86 298 0 192 0S0 86 0 192c0 87.4 117 243 168.3 307.2c12.3 15.3 35.1 15.3 47.4 0zM192 128a64 64 0 1 1 0 128 64 64 0 1 1 0-128z'/%3E%3C/svg%3E",
      iconSize: [60, 60],
      iconAnchor: [30, 30],
      popupAnchor: [0, -10]
   });

   const {
      isLoading,
      setIsLoading,
      latitude,
      setLatitude,
      longitude,
      setLongitude,
      minTemp,
      setMinTemp,
      maxTemp,
      setMaxTemp,
      moduleMinTemp,
      setModuleMinTemp,
      moduleMaxTemp,
      setModuleMaxTemp
   } = useCore();

   const [mapCenter, setMapcenter] = useState([parseFloat(latitude), parseFloat(longitude)]);

   const [map, setMap] = useState(null);
   const [mark, setMark] = useState(null);

   const { bindInput, bindOptions, bindOption, isBusy, suggestions, selectedIndex } = useAutoComplete({
      onChange: value => {
         const item = value?.item;
         if( item )
         {
            setLatitude(item?.lat);
            setLongitude(item?.lon);
         }
      },
      delay: 500,
      source: async (search) =>
      {
         try
         {
            const res = await fetch(`https://geocode.maps.co/search?q=${search}&country=IT`)
            const data = await res.json()
            return data.map(d => ({ value: d.place_id, label: d.display_name, item: d }))
         } catch (e)
         {
            return []
         }
      }
   })

   useEffect(() =>
   {
      if (map)
      {
         map.setView(mapCenter, 9);
      }

   }, [mapCenter]);

   useEffect(() =>
   {
      setMapcenter([parseFloat(latitude), parseFloat(longitude)]);
   }, [latitude, longitude]);

   const options = {
      disableDefaultUI: true
   }

   const handleModuleMinTemp = useCallback((minTemp) =>
   {
      const min = parseFloat(minTemp) + 31.25;
      const value = isNaN(min) ? '' : min;
      setModuleMinTemp(value);
   }, [setModuleMinTemp]);

   const handleModuleMaxTemp = useCallback((maxTemp) =>
   {
      const max = parseFloat(maxTemp) + 31.25;
      const value = isNaN(max) ? '' : max;
      setModuleMaxTemp(value);
   }, [setModuleMaxTemp]);

   useEffect(() =>
   {
      handleModuleMinTemp(minTemp);
      handleModuleMaxTemp(maxTemp);
   }, [minTemp, maxTemp, handleModuleMinTemp, handleModuleMaxTemp]);

   const eventHandlers = useMemo(
      () => ({
         dragend()
         {
            if (mark != null)
            {
               const latLng = mark.getLatLng();
               setLatitude(latLng?.lat.toFixed(6));
               setLongitude(latLng?.lng.toFixed(6));
               console.log('MARK', mark.getLatLng());
            }
         },
      }),
      [map, mark],
   )

   const handleMaxTemp = useCallback((e) =>
   {
      const el = e.target;
      const value = el.value;
      if (value > 60)
      {
         setMaxTemp(60);
      } else
      {
         setMaxTemp(value);
      }
   }, [setMaxTemp]);

   const handleMinTemp = useCallback((e) =>
   {
      const el = e.target;
      const value = el.value;
      if (value < -25)
      {
         setMinTemp(-25);
      } else
      {
         setMinTemp(value);
      }
   }, [setMinTemp]);

   const handleLatitude = useCallback((e) =>
   {
      setLatitude(e.target.value.replace(',', '.'));
   }, [setLatitude]);

   const handleLongitude = useCallback((e) =>
   {
      setLongitude(e.target.value.replace(',', '.'));
   }, [setLongitude]);

   const handleContinue = useCallback(() =>
   {

      if (moduleMinTemp && moduleMaxTemp)
      {
         const nextStepUrl = idConfigurazione ? `/edit/${idConfigurazione}/step-2` : '/step-2';
         navigate(nextStepUrl);
      } else
      {
         alert('I valori di temperatura minima e massima del modulo non sono correttamente configurati');
         return false;
      }
   }, [moduleMinTemp, moduleMaxTemp, idConfigurazione, navigate]);

   //  const onAutocompleteLoad = useCallback((autocomplete) => {
   //      setAutocompleteObject(autocomplete);
   //  },[]);

   //  const handlePlaceChanged = useCallback(() => {
   //      const place = autocompleteObject.getPlace();
   //      if(place)
   //      {
   //          const lat = place.geometry.location.lat();
   //          const lng = place.geometry.location.lng();
   //          setLatitude(lat);
   //          setLongitude(lng);
   //      }
   //  },[autocompleteObject, setLatitude, setLongitude]);

   //  const handleAutocompleteFieldClick = useCallback((e) => {
   //      e.target.value = '';
   //  },[]);

   return (
      <>
         <Header />
         <main className="app-mai bg-white">
            <section className="wizard">
               <Toolbar title={t('toolbar.title')} subtitle={t('toolbar.subtitle')} activeStep={1} />
               <div className="step-content">
                  <div className="container-fluid step step-1 bg-lighest">
                     <div className="row g-0">
                        <div className="layout-column first col-lg-4 d-flex flex-column">
                           <Intro icon="fa-light fa-map-location-dot" title={t('step1.intro.title')} subtitle={t('step1.intro.subtitle')}>
                              <div className="info-action">
                                 <button disabled={!moduleMinTemp || !moduleMaxTemp} onClick={handleContinue} className="btn btn-primary btn-next btn-round px-3">{t('general.continue_btn')}</button>
                              </div>
                           </Intro>
                        </div>
                        <div className="col-lg-3 col-xxl-2 bg-primary p-4 d-flex flex-column justify-content-between order-lg-2">
                           <div className='top-area'>
                              <h6 className='text-center text-white mb-3'>{t('step1.map.city_label')}</h6>
                              <div className='ui-wrapper align-items-stretch temperature-calculator'>

                                 <div className="col-12">
                                    <div className="form-row">
                                       <div className="input-wrapper">
                                          <div className="input">
                                             <div className="position-relative">
                                                <div className="flex items-center w-full">
                                                   <input
                                                      placeholder='Search'
                                                      className={`autocomplete-field flex-grow outline-none form-control btn-round${isBusy ? ' loading' : ''}`}
                                                      {...bindInput}
                                                   />
                                                   <div className="w-4 h-4 autocomplete-loader border-2 border-dashed rounded-full border-slate-500 animate-spin"></div>
                                                   {isBusy && <div className="autocomplete-loader w-4 h-4 border-2 border-dashed rounded-full border-slate-500 animate-spin"></div>}
                                                </div>
                                                {
                                                   suggestions.length > 0 && (
                                                      <ul {...bindOptions} className="autocomplete-res w-[300px] scroll-smooth absolute max-h-[260px] overflow-x-hidden overflow-y-auto" >
                                                         {
                                                            suggestions.map((_, index) => (
                                                               <li
                                                                  className={`flex items-center h-[40px] py-1 px-3 hover:bg-slate-300 ` + (selectedIndex === index && "bg-slate-300")}
                                                                  key={index}
                                                                  {...bindOption}
                                                               >
                                                                  <div className="flex items-center space-x-1">
                                                                     <div>{suggestions[index].label}</div>
                                                                  </div>
                                                               </li>
                                                            ))
                                                         }
                                                      </ul>
                                                   )
                                                }

                                             </div>
                                          </div>
                                       </div>
                                    </div>
                                 </div>


                                 <h6 className='text-center text-white mb-3 mt-4'>{t('step1.map.coords_label')}</h6>
                                 <div className="col-12 mt-3">
                                    <div className="form-row">
                                       <div className="input-wrapper">
                                          <div className="input">
                                             <input onChange={handleLatitude} placeholder={t('step1.map.latitude')} type="text" value={latitude} name="lat" className="form-control btn-round" />
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                                 <div className="col-12 mt-3">
                                    <div className="form-row">
                                       <div className="input-wrapper">
                                          <div className="input">
                                             <input onChange={handleLongitude} placeholder={t('step1.map.longitude')} type="text" value={longitude} name="lng" className="form-control btn-round" />
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                                 <h6 className='text-center text-white mb-3 mt-5'>{t('step1.map.temp_label')}</h6>
                                 <div className="col-12 mt-3">
                                    <div className="form-row">
                                       <div className="input-wrapper">
                                          <div className="input deg-suffix">
                                             <input placeholder={t('step1.map.min_temp')} type="text" onChange={handleMinTemp} value={minTemp} name="tmp_min" className="form-control btn-round" />
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                                 <div className="col-12 mt-3">
                                    <div className="form-row">
                                       <div className="input-wrapper">
                                          <div className="input deg-suffix">
                                             <input placeholder={t('step1.map.max_temp')} type="text" onChange={handleMaxTemp} value={maxTemp} name="tmp_max" className="form-control btn-round" />
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                              </div>
                           </div>
                           <div className='bottom-area'></div>
                        </div>
                        <div className="layout-column last col-lg-5 col-xxl-6 order-lg-1">
                           <div id="map-container" className="position-relative d-flex flex-column justify-content-end align-items-stretch">
                              <MapContainer ref={setMap} center={mapCenter} zoom={13} scrollWheelZoom={false}>
                                 <TileLayer
                                    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                                 />
                                 <Marker
                                    position={mapCenter}
                                    icon={mapMarker}
                                    draggable={true}
                                    eventHandlers={eventHandlers}
                                    ref={setMark}
                                 >
                                 </Marker>
                              </MapContainer>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </section>
         </main>
         <Footer />
      </>
   )
}
export default Step1;